import React, { useState } from 'react';

// plugin
import { Button, Form, InputGroup } from 'react-bootstrap';

import axios from 'axios';

import { useCookies } from 'react-cookie';

import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

// components
import Header from '../components/Header/Header';

import SignUpModal from '../components/Modal/SignUpModal';

import ResettingModal from '../components/Modal/ResettingModal';
import RegisterModal from '../components/Modal/RegisterModal';
import { useTranslation } from 'react-i18next';

const apiURL = process.env.REACT_APP_API_URL;

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

const LogInPage = () => {
  const { t } = useTranslation();

  const [, setCookie, removeCookie] = useCookies(['coias_token']);
  const [signUpModalShow, setSignUpModalShow] = useState(false);
  const [resettingModalShow, setResettingModalShow] = useState(false);
  const [loginName, setLoginName] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [signUpName, setSignUpName] = useState('');
  const [signUpEmail, setSignUpEmail] = useState('');

  const [signUpPassword, setSignUpPassword] = useState('');
  const [signUpRePassword, setSignUpRePassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [resetPassword, setResetPassword] = useState('');
  const [resetRePassword, setResetRePassword] = useState('');
  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [disableResetPassword, setDisableResetPassword] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [emailErrorColor, setEmailErrorColor] = useState('red');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [registerModalShow, setRegisterModalShow] = useState(false);

  const onSignUpHide = () => {
    setSignUpModalShow(false);
    setSignUpEmail('');
    setSignUpName('');
    setSignUpPassword('');
    setSignUpRePassword('');
    setTemporaryPassword('');
    setPasswordErrorMessage('');
    setEmailErrorMessage('');
  };

  const onResetingModalHide = () => {
    setResettingModalShow(false);
    setResetEmail('');
    setResetPassword('');
    setResetRePassword('');
    setTemporaryPassword('');
    setPasswordErrorMessage('');
    setEmailErrorMessage('');
    setDisableResetPassword(true);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${apiURL}/login`,
      headers: { 'Content-Type': 'application/json' },
      data: { username: loginName, password: loginPassword },
    })
      .then((response) => {
        removeCookie('coias_token');
        console.log(new Date(), response.data.expires_in_absolute_date);
        setCookie('coias_token', response.data, {
          path: '/',
          expires: new Date(response.data.expires_in_absolute_date),
          maxAge: response.data.expires_in + response.data.refresh_expires_in,
        });
        window.open(REDIRECT_URL);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setLoginErrorMessage(t('ユーザーネームかパスワードが違います'));
        } else if (error.response.status === 400) {
          setLoginErrorMessage(t('メールが確認されていません'));
        }
      });
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    if (signUpPassword !== signUpRePassword) {
      setPasswordErrorMessage(t('同じパスワードを入力してください'));
      return null;
    } else {
      setPasswordErrorMessage('');
    }
    axios({
      method: 'post',
      url: `${apiURL}/register`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        username: signUpName,
        password: signUpPassword,
        email: signUpEmail,
        enabled: true,
      },
    })
      .then((response) => {
        console.log(response); // Navigate('/')//TODO: email検証
        setLoginName(signUpEmail);
        setRegisterModalShow(true);
        onSignUpHide();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          setEmailErrorMessage(
            t('このメールアドレスもしくはニックネームは既に使用されています'),
          );
          setEmailErrorColor('red');
        }
        console.log();
      });
  };

  const handleSendTemporaryPassword = (e) => {
    e.preventDefault();
    if (resetEmail.length === 0) {
      setEmailErrorMessage(t('メールアドレスを入力してください'));
      setEmailErrorColor('red');
      return null;
    } else {
      setEmailErrorMessage('');
    }
    axios({
      method: 'put',
      url: `${apiURL}/send_temporary_password`,
      headers: { 'Content-Type': 'application/json' },
      data: { email: resetEmail },
    })
      .then(() => {
        setEmailErrorMessage(t('再設定メールを送信しました'));
        setEmailErrorColor('green');
        setDisableResetPassword(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setEmailErrorMessage(t('このメールアドレスは登録されていません'));
          setEmailErrorColor('red');
        } else {
          setEmailErrorMessage(t('何らかのエラーが発生しました'));
          setEmailErrorColor('red');
        }
      });
  };

  const handleReset = (e) => {
    e.preventDefault();
    if (resetPassword !== resetRePassword) {
      setPasswordErrorMessage(t('同じパスワードを入力してください'));
      return null;
    } else {
      setPasswordErrorMessage('');
    }
    axios({
      method: 'put',
      url: `${apiURL}/reset_password`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        email: resetEmail,
        temporary_password: temporaryPassword,
        new_password: resetPassword,
      },
    })
      .then((response) => {
        console.log(response);
        onResetingModalHide();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setPasswordErrorMessage(t('仮パスワードが正しくありません'));
        }
        if (error.response.status === 404) {
          setPasswordErrorMessage(t('このメールアドレスは登録されていません'));
        }
      });
  };

  return (
    <div className="l-page bg-img">
      <Header />
      {/* <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('新規登録・ログイン')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <p className="">
              {t(
                '誤検出と報告アルゴリズムの不具合を修正する必要があるため、COIASの機能を一時停止しています。再開までログインいただけません。申し訳ございませんが、復活までしばらくお待ちください。',
              )}
            </p>
          </div>
        </div>
      </div> */}
      <div className="page_wrap login_wrap">
        <div className="u-page_sub_title login_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('新規登録・ログイン')}</h3>
          <p className="f-c_white">
            {t('ログイン後のアプリ操作はPCで行ってください。')}
            <br />
            {t(
              'ご利用前に使い方動画を必ずご確認ください(特にノイズと移動天体候補の見分け方は必ず把握してください)。',
            )}
          </p>
        </div>
        <div className="u-login_contents">
          <div className="contents_wrap bg-color_white">
            <Form className="mb-5" onSubmit={(e) => handleLogin(e)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  <span className="f-s16_w700 f-en">ID </span>
                  <span>{t('(メールアドレス)')}</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="xxxxxxxxxx@gmail.com"
                  value={loginName}
                  onChange={(e) => setLoginName(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className=""
                type="password"
                controlId="formBasicPassword"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
              >
                <Form.Label>
                  <span className="f-s16_w700 f-en">password</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control type={isRevealPassword ? 'text' : 'password'} />

                  <InputGroup.Text
                    style={{ backgroundColor: 'white' }}
                    onClick={() => setIsRevealPassword(!isRevealPassword)}
                  >
                    {isRevealPassword ? (
                      <BsEyeFill size={20} />
                    ) : (
                      <BsEyeSlashFill size={20} />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                <p style={{ color: 'red' }}>{loginErrorMessage}</p>
                <div className="modal_open-password">
                  <p
                    onClick={() => {
                      setResettingModalShow(true);
                      return false;
                    }}
                  >
                    {t('パスワードをお忘れの方はこちら')}
                  </p>
                </div>
              </Form.Group>
              <div className="btn_wrap m-5">
                <Button variant="primary" type="submit" className="f-button">
                  {t('ログイン')}
                </Button>
              </div>
              <p>{t('初めてご利用する方は')}</p>
              <div className="btn_wrap">
                <Button
                  variant="primary"
                  className="f-button"
                  onClick={() => setSignUpModalShow(true)}
                >
                  {t('新規登録')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <SignUpModal
        show={signUpModalShow}
        onHide={onSignUpHide}
        signUpName={signUpName}
        setSignUpName={setSignUpName}
        signUpPassword={signUpPassword}
        setSignUpPassword={setSignUpPassword}
        signUpRePassword={signUpRePassword}
        setSignUpRePassword={setSignUpRePassword}
        signUpEmail={signUpEmail}
        setSignUpEmail={setSignUpEmail}
        handleSignUp={handleSignUp}
        passwordErrorMessage={passwordErrorMessage}
        emailErrorMessage={emailErrorMessage}
      />
      <ResettingModal
        show={resettingModalShow}
        resetEmail={resetEmail}
        setResetEmail={setResetEmail}
        resetPassword={resetPassword}
        setResetPassword={setResetPassword}
        resetRePassword={resetRePassword}
        setResetRePassword={setResetRePassword}
        temporaryPassword={temporaryPassword}
        setTemporaryPassword={setTemporaryPassword}
        onHide={onResetingModalHide}
        handleSendTemporaryPassword={handleSendTemporaryPassword}
        handleReset={handleReset}
        passwordErrorMessage={passwordErrorMessage}
        emailErrorMessage={emailErrorMessage}
        emailErrorColor={emailErrorColor}
        disableResetPassword={disableResetPassword}
      />
      <RegisterModal
        show={registerModalShow}
        onHide={() => {
          setRegisterModalShow(false);
        }}
      />
    </div>
  );
};

export default LogInPage;
