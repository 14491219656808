import React from 'react';

// components
import Footer from '../../components/Footer/Footer';

import Header from '../../components/Header/Header';

import NumberedObjectsInformationTable from '../../components/NumberedObjectsInformationTable/NumberedObjectsInfoListTable';
import { useTranslation } from 'react-i18next';

import AoImage from '../../images/data/ao.png';
import QuroImage from '../../images/data/quro.png';
import HoshizakiImage from '../../images/data/hoshizaki.png';

const Numbered = () => {
  const { t } = useTranslation();

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('確定番号天体の詳細')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <div className="data_analysis">
              <h4 className="data_analysis-title">
                {t('COIASで発見された確定番号天体のリスト')}
              </h4>
              <NumberedObjectsInformationTable />
              <br />
              <span className="mg-r_xs f-s14_w500">
                {t(
                  '(注) COIAS（すばる望遠鏡）による発見が確定した天体のみを掲載しています。',
                )}
              </span>
            </div>
            <div className="data_analysis">
              <h4 className="data_analysis-title">{t('命名された天体')}</h4>
              <h5 className="mg-t_sm mg-b_md f-s24_w700">
                (697402) Ao = 2017 BX232
              </h5>
              <img
                alt=""
                src={AoImage}
                width="400px"
                height="400px"
                className="mg-b_xs"
              />
              <h5 className="mg-t_sm mg-b_md f-s24_w700">
                (718492) Quro = 2017 FZ233
              </h5>
              <img
                alt=""
                src={QuroImage}
                width="400px"
                height="400px"
                className="mg-b_xs"
              />
              <h5 className="mg-t_sm mg-b_sm f-s24_w700">
                (719612) Hoshizaki = 2019 UW157
              </h5>
              <img
                alt=""
                src={HoshizakiImage}
                width="400px"
                height="400px"
                className="mg-b_xs"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Numbered;
