import React from 'react';

import './SideMenuStyle.scss';

// plugin
import { Link as Scroll } from 'react-scroll';

import SidebarIcon from '../../images/sidebar_icon.png';
import { useTranslation } from 'react-i18next';

const SideMenu = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {props.type === 'dictionary' ? (
        <div className="sidebar_wrap dictionary">
          <div className="sidebar_title">
            <img alt="" src={SidebarIcon} width="16px" height="16px" />
            <span className="f-s16_w700">{t('目次')}</span>
          </div>
          <div className="sidebar_contents">
            {props.title.map((output, index) => (
              <div className="sidebar_link_wrap">
                <span className="link_number">{index + 1}.</span>
                <Scroll
                  activeClass="active"
                  to={`dic_${index + 1}`}
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={300}
                  className="link_name"
                >
                  {t(output)}
                </Scroll>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="sidebar_wrap procedure">
          <div className="sidebar_title">
            <img
              alt=""
              src={SidebarIcon}
              width="16px"
              height="16px"
              style={{ marginRight: '10px' }}
            />
            <span className="f-s16_w700">{t('目次')}</span>
          </div>
          <div className="sidebar_contents">
            {props.title.map((output, index) => (
              <div className="sidebar_link_wrap">
                <span className="link_number">{index + 1}.</span>
                <Scroll
                  activeClass="active"
                  to={`pro_${index + 1}`}
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={300}
                  className="link_name"
                >
                  {t(output)}
                </Scroll>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SideMenu;
