import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const BarChart = ({ rankingData }) => {
  const { t } = useTranslation();

  const options = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: { position: 'top', aline: 'end' },
      datalabels: {
        display: true,
        anchor: 'start',
        align: 'right',
        formatter(value) {
          if (value === null || value === 0) {
            return '';
          }
          return `${value.user_name}さん`;
        },
        color: '#282828',
        font: { size: 14 },
      },
    },
    scales: {
      x: {
        ticks: { font: { size: 16 }, fontColor: '#282828' },
        title: { display: true, text: t('個'), font: { size: 25 } },
      },
      y: { ticks: { font: { size: 16 }, fontColor: '#282828' } },
    },
  };
  const labels = rankingData
    .filter((user, index) => user.ranking !== -1 && index < 10)
    .map((user) => `${user.ranking}${t('位')}`);
  const data = {
    labels, // x軸のラベルの配列
    datasets: [
      {
        label: t('測定天体数'), // 凡例
        data: rankingData.filter(
          (user, index) => user.ranking !== -1 && index < 10,
        ), // データの配列(labelsと要素数同じ)
        backgroundColor: 'rgba(56, 85, 161, 0.5)', // グラフの棒の色
        parsing: {
          xAxisKey: 'object_number',
          yAxisKey: 'ranking',
        },
      },
    ],
  };

  return (
    <div>
      <Bar data={data} ChartDataLabels={[ChartDataLabels]} options={options} />
    </div>
  );
};
export default BarChart;
