import React from 'react';
import { NavLink } from 'react-router-dom';

// components
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

import { useTranslation } from 'react-i18next';

const Issue2023Nov = () => {
  const { t } = useTranslation();

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">
            <span className="f-en">
              {t('2023年11月のCOIASの停止と報告データ修正についてのご報告')}
            </span>
          </h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <h4
              className="f-s36_w500"
              style={{ marginTop: '20px', marginBottom: '10px' }}
            >
              {t('概要')}
            </h4>
            <p className="m-indent">
              {t(
                '2023年11月中旬から12月中旬にかけて、COIASの観測報告を修正する必要が生じ、COIASを停止した上で調査と修正作業を実施しました。',
              )}
            </p>
            <p className="m-indent">
              {t(
                'この間、COIASが利用できない状態になったほか、これまでの報告を再提出する必要が生じました。ユーザの皆さまにはたいへんご迷惑をおかけ致しました。今回の事象の原因となったプログラム上の誤りを修正したため、今後は同様の原因での報告の誤差は発生しなくなる見込みです。',
              )}
            </p>

            <h4
              className="f-s36_w500"
              style={{ marginTop: '20px', marginBottom: '10px' }}
            >
              {t('発生した事象')}
            </h4>
            <ul className="list_ml">
              <li className="list_star">
                {t(
                  'COIASが報告した測定結果の一部に一様な天体位置のずれがあることが判明し、小惑星センター（MPC）から是正を求められました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  'これを受けて、COIAS開発チームでは2023年11月16日からCOIASの運用を一時停止し、原因究明および誤った報告の再提出を実施しました。',
                )}
              </li>
            </ul>

            <h4
              className="f-s36_w500"
              style={{ marginTop: '20px', marginBottom: '10px' }}
            >
              {t('発生した事象の原因')}
            </h4>
            <p className="m-indent">
              {t(
                'COIASのプログラムには大きく分けて2つの独立な誤りがあり、それらの片方もしくは両方によって天体位置のずれが引き起こされていました。',
              )}
            </p>

            <h5
              className="f-s24_w500"
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              {t('原因1')}
            </h5>
            <p className="m-indent">
              {t(
                'COIASで使用している画像ファイルの時刻を誤って扱っていた場合がありました。この画像ファイル（FITSファイル）に記録されている撮影時刻は、協定世界時（UTC）の場合と国際原子時（TAI）の場合があります。',
              )}
            </p>
            <p className="m-indent">
              {t(
                'UTCとTAIの間には、うるう秒と呼ばれる整数秒のずれがあります（',
              )}
              <a
                href="https://eco.mtk.nao.ac.jp/koyomi/wiki/B6A8C4EAC0A4B3A6BBFE.html"
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: 'underline' }}
              >
                {t('暦Wiki/協定世界時')}
              </a>
              {t(
                '）。COIASで使用するデータが撮影された期間におけるUTCとTAIの差は、36秒あるいは37秒です。',
              )}
            </p>
            <p className="m-indent">
              {t(
                'しかし、修正前のCOIASでは、画像の時刻がTAIである場合も誤ってUTCであるとして時刻を計算していました。これにより、報告の時刻が36または37秒ずれてしまいました。この事象による天体位置のずれは天体の移動速度に依存しますが、例えば毎時20 arcsec（秒角）移動する天体の場合には約0.2 arcsecに相当します。',
              )}
            </p>

            <h5
              className="f-s24_w500"
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              {t('原因2')}
            </h5>
            <p className="m-indent">
              {t(
                '座標変換時の原点を1ピクセルずらして扱っていた場合がありました。COIASでは、天体の画像上での位置 (ピクセル座標) と天球上での位置（赤経・赤緯）を相互に変換する処理を行って報告データを作成します。この際、使用するソフトウェアによって画像の原点が(0, 0)である場合（0-indexed）と(1, 1)である場合（1-indexed）がありました。',
              )}
            </p>
            <p className="m-indent">
              {t(
                '修正前のCOIASでは、これらの原点の扱いの違いを考慮できていなかったため、手動測定の場合に天体の位置が赤経方向に1ピクセルずれてしまう状況でした（自動検出の天体は影響を受けていませんでした）。1ピクセルのずれは、標準の設定の場合0.35 arcsec前後に相当します。',
              )}
            </p>

            <h4
              className="f-s36_w500"
              style={{ marginTop: '20px', marginBottom: '10px' }}
            >
              {t('対応')}
            </h4>
            <ul className="list_ml">
              <li className="list_star">
                {t(
                  '時刻に関しては、TAIとして解釈し直すことで、画像ファイルおよび今までの測定データの時刻を修正しました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '座標に関しては、手動測定された観測それぞれについて座標変換時のずれを補正しました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '上記2点の修正を、今までにCOIASから報告された約30万件のデータに適用し、MPCに再提出を行いました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  'COIAS内で使用されている解析プログラムについても、正しく時刻の解釈および座標変換を行うように修正し、今後の測定で同様の誤差が生じないようにしました。',
                )}
              </li>
            </ul>
            <p style={{ marginTop: '10px' }}>
              {t(
                'これらの対応によってCOIASで報告した天体の位置が正確になり、今後の天体の特定や軌道改良がより効果的になることが期待されます。',
              )}
            </p>

            <h4
              className="f-s36_w500"
              style={{ marginTop: '20px', marginBottom: '10px' }}
            >
              {t('影響')}
            </h4>
            <ul className="list_ml">
              <li className="list_star">
                {t('誤差の原因究明と修正の対応中、COIASが停止しました。')}
              </li>
              <li className="list_star">
                {t(
                  '再提出に際して、これまでのCOIASからの報告が一時的にMPCから削除されました。これらの大部分は修正後のデータの再提出によって復旧しました。',
                )}
              </li>
              <li className="list_star">
                {t('データを修正したことで、修正前のデータを用いた"')}
                <a
                  href="https://minorplanetcenter.net/wamo/"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ textDecoration: 'underline' }}
                >
                  {t('Where Are My Observations (WAMO)?')}
                </a>
                {t(
                  '"での検索ができなくなりました。これに関して、お手元のレポートファイル (~send_mpc.txt) の測定行を修正後のものに置き換え、WAMOでの検索ができるようにする特設ページを作成しました。特設ページは',
                )}
                <NavLink to="/special/fix-send-mpc-2023nov">
                  {t('こちら。')}
                </NavLink>
              </li>
            </ul>

            <h4
              className="f-s36_w500"
              style={{ marginTop: '20px', marginBottom: '10px' }}
            >
              {t('タイムライン')}
            </h4>
            <ul className="list_ml">
              <li className="list_star">
                {t(
                  '2023年11月16日18時: MPCからの指摘を受けて、COIASの一時停止を行いました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '2023年11月16日: 指摘内容を精査し、誤差の原因を前述の原因1・原因2に絞り込みました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '2023年11月19日: 原因1・原因2それぞれについてプログラムや既存のデータを詳細に調査し、正確な原因箇所と誤差の量を特定しました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '2023年11月22日: 既存のデータを修正するためのプログラムを作成しました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '2023年11月24日: プログラムによって修正した測定データの誤差が大幅に改善したことをMPCに確認しました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '2023年11月27日から12月10日: COIAS内部で保有しているデータの全てを修正し、それを元に報告データを再作成するプログラムを作成しました。並行して、データ修正前に実行すべき前処理を行いました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '2023年12月11日から12月14日: 修正済みの報告データを作成し、問題なく修正できていることを確認しました。',
                )}
              </li>
              <li className="list_star">
                {t(
                  '2023年12月15日から12月21日: 修正済みの報告データをMPCに再提出しました。',
                )}
              </li>
            </ul>
            <p style={{ marginTop: '20px' }}>{t('文責: COIAS開発チーム')}</p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Issue2023Nov;
