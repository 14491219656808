import React, { useState } from 'react';

// components
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';

// image
import GuideMoving01 from '../images/guide/guide-moving-01.png';
import GuideMoving02 from '../images/guide/guide-moving-02.png';
import GuideMoving03 from '../images/guide/guide-moving-03.png';
import GuideMoving04 from '../images/guide/guide-moving-04.png';
import GuideMoving05 from '../images/guide/guide-moving-05.png';
import ExampleMoving01 from '../images/guide/example-moving-01.gif';
import ExampleMoving02 from '../images/guide/example-moving-02.gif';
import ExampleMoving03 from '../images/guide/example-moving-03.gif';
import ExampleMoving04 from '../images/guide/example-moving-04.gif';
import ExampleMoving05 from '../images/guide/example-moving-05.gif';

const Guide = () => {
  const { t } = useTranslation();
  const [basicplayer, setBasicPlayer] = useState();
  const [intermediatePlayer, setIntermediatePlayer] = useState();

  const GuideImagesOK = [
    {
      illust: GuideMoving01,
      ex: ExampleMoving01,
      note: t('一定の大きさ、速度で移動している'),
    },
    {
      illust: GuideMoving02,
      ex: ExampleMoving02,
      note: t('一定の大きさ、速度で移動している（形が伸びていてもOKです）'),
    },
  ];
  const GuideImagesNG = [
    {
      illust: GuideMoving03,
      ex: ExampleMoving03,
      note: t('ノイズを天体と誤判定している'),
    },
    {
      illust: GuideMoving04,
      ex: ExampleMoving04,
      note: t('明るい星の周りの光芒'),
    },
    {
      illust: GuideMoving05,
      ex: ExampleMoving05,
      note: t('画像間で位置の動かない天体を巻き込んでいる'),
    },
  ];

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('使い方ガイド')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <h3 className="content_title f-content_title f-c_primary">
            {t('推奨環境')}
          </h3>
          <div>
            <p className="guide_caution">
              {t('COIASアプリはPCでのご利用をお願いします。')}
              <br />
              {t('スマートフォンやタブレットでは正常にご利用できません。')}
              <br />
              {t(
                'Webブラウザは、Google Chromeを推奨します。SafariやFirefoxでは表示が崩れることがあります。',
              )}
            </p>
          </div>
          <h3 className="content_title f-content_title f-c_primary">
            {t('移動天体を見つけるには')}
          </h3>
          <div className="contents_text mg-b_lg">
            <p className="mg-b_md">
              {t(
                'COIASの目的は、小惑星をはじめとする太陽系天体を発見することです。これらの天体は、恒星や銀河などに対して',
              )}
              <span className="f-s16_w700 m-line">
                {t(
                  '時間が経つにつれて動いていくため、「移動天体」と呼ばれます。',
                )}
              </span>
            </p>
            <p className="mg-b_md">
              {t(
                'COIASの探索画面では、連続した複数の画像と、天体を囲む赤や黒の枠が表示されます。黒い枠は移動天体の候補を示していますが、多くのノイズが混じっています。',
              )}
              {t(
                '本物の移動天体を正しく見つけるために、いくつかの例を示します。',
              )}
            </p>
            <h4 className="mg-b_md f-s24_w700">{t('移動天体である例')}</h4>
            {GuideImagesOK.map((item) => {
              return (
                <>
                  <p>{item.note}</p>
                  <div className="flex-container mg-b_sm">
                    <img
                      alt=""
                      src={item.illust}
                      width="42%"
                      maxHeight="270px"
                      className="flex-item mg-b_xs"
                    />
                    <img
                      alt=""
                      src={item.ex}
                      width="52.5%"
                      maxHeight="270px"
                      className="flex-item mg-b_xs"
                    />
                  </div>
                </>
              );
            })}
            <h4 className="mg-b_md f-s24_w700">{t('移動天体ではない例')}</h4>
            {GuideImagesNG.map((item) => {
              return (
                <>
                  <p>{item.note}</p>
                  <div className="flex-container mg-b_sm">
                    <img
                      alt=""
                      src={item.illust}
                      width="42%"
                      maxHeight="270px"
                      className="flex-item mg-b_xs"
                    />
                    <img
                      alt=""
                      src={item.ex}
                      width="52.5%"
                      maxHeight="270px"
                      className="flex-item mg-b_xs"
                    />
                  </div>
                </>
              );
            })}
          </div>
          <h3 className="content_title f-content_title f-c_primary">
            {t('基本編')}
          </h3>
          <div className="contents_text">
            <p className="mg-b_md">
              {t(
                '初めてCOIASを使用する方向けに、小惑星探索の最も基本的な手順をご紹介します。',
              )}
            </p>
            <div className="contents_movie mg-b_md">
              <YouTube
                videoId="piTzbqrAgok"
                opts={{
                  height: '100%',
                  width: '100%',
                }}
                className="movie_wrap"
                onReady={(event) => setBasicPlayer(event.target)}
              />
            </div>
            <div className="list_content about_coias">
              <ul>
                <li>
                  <span className="step_title">Step. 1</span>
                  <span>{t('COIASとは？')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => basicplayer.seekTo(12, true)}
                  >
                    0:12
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 2</span>
                  <span>{t('ユーザ登録・ログイン')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => basicplayer.seekTo(69, true)}
                  >
                    1:09
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 3</span>
                  <span>{t('解析する画像を自動で選ぶ')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => basicplayer.seekTo(202, true)}
                  >
                    3:22
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 4</span>
                  <span>{t('探索準備・自動解析')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => basicplayer.seekTo(312, true)}
                  >
                    5:12
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 5</span>
                  <span>{t('新天体候補の探索')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => basicplayer.seekTo(354, true)}
                  >
                    5:54
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 6</span>
                  <span>{t('手動で測定する')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => basicplayer.seekTo(753, true)}
                  >
                    12:33
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 7</span>
                  <span>{t('レポートで新天体の候補を報告する')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => basicplayer.seekTo(973, true)}
                  >
                    16:13
                  </button>
                </li>
                <li>
                  <span className="step_title">Extra</span>
                  <span>{t('補足')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => basicplayer.seekTo(1151, true)}
                  >
                    19:11
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <h3 className="content_title f-content_title f-c_primary">
            {t('中級編')}
          </h3>
          <div className="contents_text">
            <p className="mg-b_md">
              {t(
                'より詳しく小惑星探索をしたい方向けに、COIASの一歩進んだ使い方をご紹介します。',
              )}
            </p>
            <div className="contents_movie mg-b_md">
              <YouTube
                videoId="15Zu_o3aKWA"
                opts={{
                  height: '100%',
                  width: '100%',
                }}
                className="movie_wrap"
                onReady={(event) => setIntermediatePlayer(event.target)}
              />
            </div>
            <div className="list_content about_coias">
              <ul>
                <li>
                  <span className="step_title">Step. 1</span>
                  <span>{t('画像の選び方')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(35, true)}
                  >
                    0:35
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 2</span>
                  <span>{t('パラメーター設定')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(215, true)}
                  >
                    3:35
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 3</span>
                  <span>{t('観測日と仮符号')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(368, true)}
                  >
                    6:08
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 4</span>
                  <span>{t('探索のコツ')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(444, true)}
                  >
                    7:24
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 5</span>
                  <span>{t('手動測定1つ目')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(669, true)}
                  >
                    11:09
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 6</span>
                  <span>{t('手動測定2つ目')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1015, true)}
                  >
                    16:55
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 7</span>
                  <span>{t('手動測定3つ目')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1145, true)}
                  >
                    19:05
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 8</span>
                  <span>{t('手動測定4つ目')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1315, true)}
                  >
                    21:55
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 9</span>
                  <span>{t('解析率100%でも再解析')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1360, true)}
                  >
                    22:40
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 10</span>
                  <span>{t('レポートモードを詳しく')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1443, true)}
                  >
                    24:03
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 11</span>
                  <span>{t('最終確認')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1518, true)}
                  >
                    25:18
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 12</span>
                  <span>{t('見逃し天体を手動測定')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1550, true)}
                  >
                    25:50
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 13</span>
                  <span>{t('最終確認2回目')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1705, true)}
                  >
                    28:25
                  </button>
                </li>
                <li>
                  <span className="step_title">Step. 14</span>
                  <span>{t('レポートと送信')} </span>
                  <button
                    className="m-plain-button"
                    onClick={() => intermediatePlayer.seekTo(1740, true)}
                  >
                    29:00
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <h3 className="content_title f-content_title f-c_primary">
            {t('上級編')}
          </h3>
          <div className="contents_text">
            <p className="mg-b_md">
              {t(
                'COIASの利用者の方々が、発展的な内容を記事にまとめてくださいました。',
              )}
              {t(
                '難しい内容も含まれていますが、よりCOIASを使いこなしたい方は参考にしてみてください。',
              )}
            </p>
            <p>
              <ul className="guide-ul">
                <li>
                  <a
                    href="https://note.com/icigasu_shine/n/n25189f86665c"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    "{t('COIAS Tips集')}" by DMP and COIAS user
                  </a>
                </li>
                <li>
                  <a
                    href="https://note.com/icigasu_shine/n/n1ac675232734"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    "{t('小惑星のIdentificationで仮符号をもぎ取ろう')} -
                    {t('COIAS Tips集')}-" by DMP and COIAS user
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Guide;
