import React from "react";

// plugin
import { Link as Scroll } from "react-scroll";

// components
import Footer from "../../components/Footer/Footer";

import Header from "../../components/Header/Header";

import SideMenu from "../../components/SideMenu/SideMenu";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const titles = [
  "小惑星とは？",
  "小惑星の発見と名前がつくまで",
  "パーソナル符号",
  "仮符号",
  "COIASで仮符号を取得するには その1",
  "COIASで仮符号を取得するには その2",
  "確定番号",
  "発見と命名",
  "仮符号のルール",
];

const Procedure = () => {
  const { t } = useTranslation();
  return (
    <div className="l-page bg-img">
      <div className="page_wrap add_sidebar">
        <Header />
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">
            {t("小惑星を発見するには？")}
          </h3>
        </div>
        <div className="u-contents_main bg-color_blue-white">
          <div className="add_sidebar">
            <SideMenu title={titles} type="procedure" />
            <div className="contents_wrap procedure">
              <div className="glossary_wrap" id="pro_1">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[0])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent mg-b_xs">
                    {t(
                      "火星と木星の間を公転する岩石状の小天体を小惑星といいます。小惑星には地球に近づくような軌道をもつ地球接近天体（NEO：near-Earth objects）と呼ばれる天体もあります。より詳しくは"
                    )}
                    <NavLink to="/details/dictionary">{t("こちら。")}</NavLink>
                    {t(
                      "小惑星の発見者になると名前を提案する権利が得られます。"
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="pro_2">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[1])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      "COIASは未発見小惑星を見つけることができるアプリです。しかし、見つけたからといって、すぐに発見とはならず、名前をつけることはできません。それでは、どうすれば発見となり名前をつけることができるのでしょうか？そのためにまず小惑星の名前について知っておく必要があります。"
                    )}

                    <Scroll
                      activeClass="active"
                      to="pro_3"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={300}
                    >
                      {t("パーソナル符号")}
                    </Scroll>
                    {t("・")}
                    <Scroll
                      activeClass="active"
                      to="pro_4"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={300}
                    >
                      {t("仮符号")}
                    </Scroll>
                    {t("・")}
                    <Scroll
                      activeClass="active"
                      to="pro_7"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={300}
                    >
                      {t("確定番号")}
                    </Scroll>
                    {t("・")}
                    <Scroll
                      activeClass="active"
                      to="pro_8"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={300}
                    >
                      {t("発見と命名")}
                    </Scroll>
                    {t("、と順を追って読み進めてください。また、")}
                    <Scroll
                      activeClass="active"
                      to="pro_5"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={300}
                    >
                      {t("COIASで仮符号を取得するには その1")}
                    </Scroll>
                    {t("・")}
                    <Scroll
                      activeClass="active"
                      to="pro_6"
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={300}
                    >
                      {t("COIASで仮符号を取得するには その2")}
                    </Scroll>
                    {t("も参考にしてください。")}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="pro_3">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[2])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      "COIASで未発見小惑星を見つける時に、小惑星候補マークの上にH000001といった番号がついていると思います。これはCOIASが独自につけた小惑星の名前であり、パーソナル符号と言います。COIASで見つけた小惑星の位置に仮符号や確定番号のついた小惑星がいない場合、それは未発見の小惑星ということになります。そのような小惑星には、独自の番号をつけて報告することになります。パーソナル符号は７文字であればなんでも良いのですが、COIASではHyperSuprime-Camの頭文字のHをつけて後に連番としています。パーソナル符号で報告した観測データは小惑星の軌道を管理する国際的な機関MPC(MinorPlanetCenter)に保管され、追観測結果を待つことになります。あるいは、他の観測所がすでにMPCに報告していた観測の追観測結果として扱われるかもしれません。このような状況もあるため、未発見小惑星を見つけたからといって即座に発見とは言えないことになります。"
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="pro_4">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[3])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      "パーソナル符号でMPCに観測報告を行った小惑星に対して、複数日以上で追観測に成功した場合、その小惑星には仮符号という仮の名前がMPCによって割り当てられます。これは軌道がまだ正確に決まっていない小惑星に付けられる整理番号です。例えば「1998KY26」の場合、1998年の5月下旬に観測され仮符号を付与された674番目の天体ということを表しています。すでに仮符号が付いている天体はCOIASでは既知の天体として位置測定と測光が行われています。仮符号のついた小惑星は正確な軌道が決まっていないので、時には行方不明になることもあります。従って、仮符号のついた小惑星の測定結果を報告することもとても大切です。"
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="pro_5">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[4])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      "COIASで扱う画像の中には、あまり多くありませんが複数日で同じ領域を観測したものもあります。特に連続した二日間で同じ領域を観測していると、初日の画像で見つけた小惑星が二日目の画像にも写っているかもしれません。そのような小惑星は他の観測所の追観測を待たずに仮符号を取得できるかもしれません。複数日以上の画像の選択方法は"
                    )}
                    <NavLink to="/guide">{t("使い方ガイド")}</NavLink>
                    {t("を参考にしてください。")}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="pro_6">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[5])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      "仮符号がついた小惑星は正式な発見ではないのですが、仮符号の付与に貢献した観測所/観測者/測定者は公表されます。観測所/観測者/測定者としては仮符号がついた時点で”おめでとう”という気持ちになります。ただし、COIASの場合は少し注意が必要です。COIASで使うアーカイブデータは2014年から2021年に取得されています。2014年のデータから未発見の小惑星を見つけて2023年に報告したとします。しかし、例えば2018年に他の観測所が同じ小惑星を測定しすでにMPCに報告していた場合、仮符号の付与に貢献した観測所は2018年に報告した観測所となります。つまり先に報告した方が優先されます。COIASで仮符号を取得するには、年代の新しい2021年のデータから解析を始めた方が良いかもしれません。"
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="pro_7">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[6])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      "仮符号のついた小惑星に対し十分な追観測が行われ、確かな軌道が得られるとその小惑星には通し番号がつけられます。この番号は確定番号と呼ばれ、MPCによって決められます。確定番号のついた小惑星を”確定小惑星”と言ったり、通称で”ナンバード(Numbered)”と呼んだりします。確定小惑星もCOIASでは既知の天体として位置測定と測光が行われています。軌道をより正確なものにするために、確定小惑星であっても測定結果を報告することは大切です。"
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="pro_8">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[7])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent mg-b_xs">
                    {t(
                      "確定小惑星となった時点で初めて正式な発見となり、発見者が決定されます。発見者はMPCが認定します。発見者になると、その小惑星の名前を国際天文学連合（IAU）に提案する権利が得られます。そして、IAUから承認が得られると晴れて命名となります。小惑星は確定小惑星となるまでに長い年月がかかる場合があります。例えば、2009年に美星スペースガードセンターで小学生・高校生が見つけた未知の小惑星は即座に仮符号を取得したのち、2013年に確定番号が割り当てられました。そして、2021年に小惑星「Ibara」と命名しました。より正確な発見者の定義は"
                    )}
                    <a
                      href="https://www.minorplanetcenter.net/mpec/K10/K10U20.html"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {t("こちら")}
                    </a>
                    {t("になります。")}
                  </p>
                  <p className="m-indent">
                    {t(
                      "COIASで報告した未発見の小惑星に名前をつけられるのか？と思っている方も多いと思います。ここまでご説明したように、報告がMPCに認められ、確定番号が付与されるまでには4～10年以上の長い年月がかかります。さらには、他の観測所の報告タイミングや、追観測の可否に左右されるので、必ず発見者になれるわけではありません。とはいえ、未知の天体・既知の天体にかかわらず、より多くの測定結果を報告していくことが国際的な小惑星の観測への貢献として非常に重要です。皆様の積極的なご参加をお待ちしています。"
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="pro_9">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[8])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      "例えば「1998 KY26」の場合、1998は観測された西暦を表します。アルファベットの１文字目は観測の時期で、1月から12月までの各月を上旬と下旬の計24ブロックに分けたものです。例えば、1月1日から1月15日までの観測は”A”、1月16日から1月31日は“B”となります。“I”は”1”や”J”と紛らわしいので使いません。アルファベットの2文字目は半月ごとの発見の順番です。ここでも”I”は使いません。半月で25個(Z番目)以上の観測報告があった場合は、アルファベットがZまで到達した回数を添字につけます。例えば1998年の5月下旬の25個目の報告は「1998 KZ」、その次以降は「1998 KA1」、「1998 KB1」…50番目は「1998 KZ1」となり51番目以降は「1998 KA2」、「1998 KB2」…「1998 KZ2」「1998 KA3」…となります。「1998 KY26」の場合は、Zまでの到達が26回あって25×26、さらにAから数え始めてYは24番目に現れるアルファベットなので+24、つまり25×26+24=674ということで5月下旬に観測され仮符号を付与された674番目の天体ということを表しています。"
                    )}
                  </p>
                </div>
              </div>
              {/* <div className="glossary_wrap" id="pro_10">
<p className="glossary_wrap-title m-letter_space">{titles[9]}</p>
<div className="glossary_wrap-text">
<p className="m-indent"></p>
</div>
</div> */}
            </div>
          </div>
          {/*<Footer />*/}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Procedure;
