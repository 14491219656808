import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill } from 'react-icons/bs';

const apiURL = process.env.REACT_APP_API_URL;

const FixSendMpc2023Nov = () => {
  const { t } = useTranslation();
  const [oldSendMpc, setOldSendMpc] = useState('');
  const [newSendMpc, setNewSendMpc] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSendMpc = () => {
    setIsCopied(false);
    setLoading(true);
    let params = new URLSearchParams();
    params.append('send_mpc', oldSendMpc);
    axios
      .post(`${apiURL}/fix_send_mpc_2023nov`, params)
      .then((res) => {
        const _newSendMpc = res.data.result.new_send_mpc;
        if (_newSendMpc) {
          setNewSendMpc(_newSendMpc);
        }
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const pasteFromClipboard = async () => {
    setOldSendMpc(await global.navigator.clipboard.readText());
  };
  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(newSendMpc);
    setIsCopied(true);
  };

  useEffect(() => {
    let timer;
    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isCopied]);

  return (
    <div className="l-page bg-img">
      <div className="page_wrap">
        <Header />
        <div className="u-contents_main bg-color_white">
          <h3
            className="content_title f-content_title f-c_primary"
            style={{ marginBottom: '30px' }}
          >
            {t('2023年冬の再提出に伴うsend_mpcファイルの修正')}
          </h3>
          <div className="contents_text sendmpc-converter-container mg-b_lg">
            <p>{t('修正前のsend_mpc.txt（中身全体を貼り付けてください）')}</p>
            <div className="textarea-wrapper">
              <textarea
                value={oldSendMpc}
                className="textarea"
                onChange={(e) => setOldSendMpc(e.target.value)}
                placeholder=""
                spellCheck="false"
              />
              <button className="copy-button btn" onClick={pasteFromClipboard}>
                Paste from Clipboard
              </button>
            </div>
            <button className="f-button btn btn-primary" onClick={getSendMpc}>
              {t('変換')}
            </button>
            <p>{t('修正後のsend_mpc.txt')}</p>
            <div className="textarea-wrapper">
              <textarea
                value={newSendMpc}
                id="resulttextarea"
                className="result-textarea"
                readOnly
                placeholder=""
                spellCheck="false"
                onClick={(e) => e.target.select()}
              />
              <div
                className="loading"
                style={{ display: loading ? 'block' : 'none' }}
              >
                <Spinner animation="border" className="spinner" />
              </div>
              <button className={'copy-button btn'} onClick={copyToClipboard}>
                Copy to clipboard
                <BsCheckCircleFill
                  className={`checkmark ${isCopied ? 'copied' : ''}`}
                />
              </button>
            </div>
          </div>
          <h3
            className="content_title f-content_title f-c_primary"
            style={{ marginBottom: '30px' }}
          >
            Advanced
          </h3>
          <div className="contents_text">
            <p>
              {t(
                '手動では難しいほど多数のファイルを修正する必要がある場合は、APIに直接POSTリクエストを送信してください。',
              )}
            </p>
            <p>
              {t(
                'ただし、同時に大量のリクエストを送らないようにしてください。',
              )}
            </p>
            <p>{t('例')}:</p>
            <p style={{ textAlign: 'left' }}>
              <code>
                curl -F &quot;send_mpc=&lt;send_mpc.txt&quot;
                https://web-coias.u-aizu.ac.jp/api/fix_send_mpc_2023nov | jq -r
                '.result.new_send_mpc' &gt; new_send_mpc.txt
              </code>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default FixSendMpc2023Nov;
