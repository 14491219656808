import React from 'react';

import { useTranslation } from 'react-i18next';

import Markdown from 'markdown-to-jsx';

import newsJP from '../texts/news.yaml';

import newsEN from '../texts/news-en.yaml';

// components
import Footer from '../components/Footer/Footer';

import Header from '../components/Header/Header';

import LinkWithProps from '../components/LinkWithProps';

const News = () => {
  const { i18n } = useTranslation();

  const news = i18n.language === 'en' ? newsEN : newsJP;
  const NewsList = news.map((item) => {
    return (
      <div className="news_contents">
        <p className="f-en f-s20_w500">{item.date}</p>
        <p className="f-c_primary f-s16_w700 mg-b_xs">{item.title}</p>
        {item.image && (
          <img
            alt=""
            src={require(`../images/${item.image}`)}
            className="news_img mg-b_xs"
          />
        )}
        <Markdown
          children={item.text}
          options={{
            overrides: {
              a: {
                component: LinkWithProps,
                props: { target: '_blank', rel: 'noreferrer noopener' },
              },
            },
          }}
        />
      </div>
    );
  });

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white f-en">News</h3>
        </div>
        <div className="u-contents_main bg-color_white">{NewsList}</div>
        <Footer />
      </div>
    </div>
  );
};

export default News;
