import React, { useState } from 'react';

import './ModalStyle.scss';

// plugin
import { Button, Form, Modal, InputGroup } from 'react-bootstrap';

import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const ResettingModal = ({
  show,
  onHide,
  resetEmail,
  setResetEmail,
  temporaryPassword,
  setTemporaryPassword,
  resetPassword,
  setResetPassword,
  resetRePassword,
  setResetRePassword,
  passwordErrorMessage,
  handleSendTemporaryPassword,
  handleReset,
  emailErrorMessage,
  emailErrorColor,
  disableResetPassword,
}) => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();

          setIsRevealPassword(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('パスワードの再設定')}</Modal.Title>
        </Modal.Header>
        <Form className="contents_wrap" onSubmit={(e) => handleReset(e)}>
          <Modal.Body className="mb-5">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                <span className="label_text">
                  {t('メールアドレスを入力してください')}
                </span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="xxxxxxxxxx@gmail.com"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
              <p style={{ color: emailErrorColor }}>{emailErrorMessage}</p>
            </Form.Group>
            <div className="btn_wrap m-5 send-temporary-password-button">
              <Button
                variant="primary"
                className="f-button"
                onClick={(e) => {
                  handleSendTemporaryPassword(e);
                }}
              >
                {t('パスワード再設定メール送信')}
              </Button>
            </div>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                <span className="label_text">
                  {t('再設定メールに記載の仮パスワードを入力してください')}
                </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={isRevealPassword ? 'text' : 'password'}
                  value={temporaryPassword}
                  disabled={disableResetPassword}
                  onChange={(e) => setTemporaryPassword(e.target.value)}
                  required
                />
                <InputGroup.Text
                  style={{ backgroundColor: 'white' }}
                  onClick={() => setIsRevealPassword(!isRevealPassword)}
                >
                  {isRevealPassword ? (
                    <BsEyeFill size={20} />
                  ) : (
                    <BsEyeSlashFill size={20} />
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                <span className="label_text">
                  {t('新しいパスワードを入力してください')}
                </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={isRevealPassword ? 'text' : 'password'}
                  value={resetPassword}
                  disabled={disableResetPassword}
                  onChange={(e) => setResetPassword(e.target.value)}
                  required
                />
                <InputGroup.Text
                  style={{ backgroundColor: 'white' }}
                  onClick={() => setIsRevealPassword(!isRevealPassword)}
                >
                  {isRevealPassword ? (
                    <BsEyeFill size={20} />
                  ) : (
                    <BsEyeSlashFill size={20} />
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="" controlId="formBasicPassword">
              <Form.Label>
                <span className="label_text">
                  {t('もう一度パスワードを入力してください')}
                </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={isRevealPassword ? 'text' : 'password'}
                  value={resetRePassword}
                  disabled={disableResetPassword}
                  onChange={(e) => setResetRePassword(e.target.value)}
                  required
                />
                <InputGroup.Text
                  style={{ backgroundColor: 'white' }}
                  onClick={() => setIsRevealPassword(!isRevealPassword)}
                >
                  {isRevealPassword ? (
                    <BsEyeFill size={20} />
                  ) : (
                    <BsEyeSlashFill size={20} />
                  )}
                </InputGroup.Text>
              </InputGroup>
              <p style={{ color: 'red' }}>{passwordErrorMessage}</p>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="two_button">
            <Button variant="secondary" onClick={onHide}>
              {t('キャンセル')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={disableResetPassword}
            >
              {t('再設定')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ResettingModal;
