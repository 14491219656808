import React from 'react';

import Markdown from 'markdown-to-jsx';

import newsJP from '../texts/news.yaml';

import newsEN from '../texts/news-en.yaml';

// components
import Footer from '../components/Footer/Footer';

import Header from '../components/Header/Header';

import LinkWithProps from '../components/LinkWithProps';

// plugin
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import { BsTwitter } from 'react-icons/bs';

// image
import Icon from '../images/icon.png';
import { useTranslation } from 'react-i18next';

const TopPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const news = i18n.language === 'en' ? newsEN : newsJP;

  const NewsListShort = news.slice(0, 5).map((item) => {
    return (
      <div className="contents_text mg-b_md">
        <p className="f-en f-s20_w500 mg-b_xs">{item.date}</p>
        <p className="contents_text-title f-s20_w500 f-c_primary">
          {item.title}
        </p>
        <Markdown
          className="contents_text-detail"
          children={item.text}
          options={{
            overrides: {
              a: {
                component: LinkWithProps,
                props: { target: '_blank', rel: 'noreferrer noopener' },
              },
            },
          }}
        />
      </div>
    );
  });

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        {window.innerWidth < 700 ? (
          <span
            className="f-c_white"
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
              position: 'relative',
              top: '90px',
              left: '5px',
            }}
          >
            {t('PC用のウェブサイトです。')}
            <br />
            {t('スマートフォンでは表示が崩れることがあります。')}
          </span>
        ) : (
          <></>
        )}
        <div className="u-page_title bg-color_transparent">
          <div className="title_wrap f-en">
            <h1 className="f-title f-c_white">COIAS</h1>
            <h2 className="f-sub_title f-c_white">
              Come On! Impacting ASteroids
            </h2>
            <div className="twitter-button">
              <Button
                className="f-button"
                type="button"
                variant="primary"
                onClick={() => window.open('https://twitter.com/coias_t09')}
              >
                <BsTwitter /> {t('公式twitter')}
              </Button>
            </div>
          </div>
        </div>
        <div className="u-contents_main bg-color_secondary">
          <h3 className="f-content_title f-c_white mg-b_xl">
            {i18n.language === 'en' ? (
              <>
                <span className="f-content_title">What is </span>
                <span className="f-s48_w700 f-en">COIAS?</span>
              </>
            ) : (
              <>
                <span className="f-s48_w700 f-en">COIAS</span>
                <span className="f-content_title">ってなに？</span>
              </>
            )}
          </h3>
          <img
            alt="icon"
            src={Icon}
            width="200px"
            height="200px"
            className="mg-b_lg"
          />
          <div className="contents_text f-c_white mg-b_lg">
            <span className="f-s16_w700">
              COIAS(Come On! Impacting ASteroids)
            </span>
            {t(
              'は、小惑星をはじめとする未発見天体の探索を研究者と一般の市民・学生の方たちが協力して実施するために開発されたウェブアプリケーションです。',
            )}
          </div>
          <Button
            className="f-button"
            type="button"
            variant="primary"
            onClick={() => navigate('/about_coias')}
          >
            {t('もっと詳しく')}
          </Button>
        </div>
        <div className="u-contents_main bg-color_white">
          <h3 className="f-s48_w700 f-c_primary mg-b_xl f-en">News</h3>
          <div className="top_shortnews_contents">{NewsListShort}</div>
          <Button
            className="f-button"
            variant="secondary"
            onClick={() => navigate('/news')}
          >
            {t('もっと見る')}
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TopPage;
