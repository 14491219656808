import React from 'react';

// components
import Footer from '../../components/Footer/Footer';

import Header from '../../components/Header/Header';

import SideMenu from '../../components/SideMenu/SideMenu';

// image
import HorizontalCoordinateSystem from '../../images/dictionary/horizontal-coordinate-system.png';

import MPCFormat from '../../images/MPC_format.png';
import { useTranslation } from 'react-i18next';

const titles = [
  '小惑星',
  'NEO',
  'すばる望遠鏡',
  'HSC',
  '小惑星の観測',
  '波長とフィルター',
  '天体の位置の表し方',
  'COIASで用いる観測画像',
  'ブリンク',
  'MPC',
  'MPCフォーマット',
  'スペースガード',
];

const Dictionary = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="l-page bg-img">
      <div className="page_wrap add_sidebar">
        <Header />
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('用語解説集')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="add_sidebar">
            <SideMenu title={titles} type="dictionary" />
            <div className="contents_wrap dictionary">
              <div className="glossary_wrap" id="dic_1">
                <p className="glossary_wrap-title">{t(titles[0])}</p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      '太陽のまわりを公転する天体のうち、惑星と準惑星、それらの衛星を除いた小天体を「太陽系小天体」と呼びます。その中でも主に',
                    )}
                    <span className="m-line f-s16_w700">
                      {t('木星の軌道周辺より内側にあるものを 「小惑星」')}
                    </span>
                    {t(
                      'と呼びます。小惑星はその軌道によって分類されており、火星と木星の間の「メインベルト小惑星（main-belt asteroids: MBA）」、 地球軌道付近に存在する「地球接近小惑星（near-Earth asteroids: NEA）」、木星軌道上の木星から60°前後方に存在する「木星トロヤ群小惑星（Jupiter Trojans: JT）」などに大別されます。地球から遠い天体ほど、観測時のみかけの動きはゆっくりになります。COIASで小惑星を探すときに意識してみると面白いかもしれません。',
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="dic_2">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[1])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t('NEOは、near-Earth object（地球接近天体）の略称で、')}
                    <span className="m-line f-s16_w700">
                      {t(
                        '地球に接近する軌道を持つ天体（彗星、小惑星、流星体）の総称',
                      )}
                    </span>
                    {t(
                      'です。NEOのうち小惑星のみを指したものがNEA（near-Earth asteroids）です。',
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="dic_3">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[2])}
                </p>
                <div className="glossary_wrap-text">
                  {i18n.language === 'en' ? (
                    <p className="m-indent">
                      The Subaru Telescope is
                      <span className="m-line f-s16_w700">
                        {' the big telescope '}
                      </span>
                      located at the summit of Mauna Kea, Hawaii, with an 8.2 m
                      aperture. The primary mirror is a smooth single-piece
                      mirror and is the largest in the world. Its
                      light-gathering power is so great that it can observe
                      stars up to about 27 magnitude. For more information,
                      please refer to
                      <a
                        href="https://www.subarutelescope.org/en/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {' the official website.'}
                      </a>
                    </p>
                  ) : (
                    <p className="m-indent">
                      すばる望遠鏡は、ハワイ・マウナケア山頂にある口径 8.2 m の
                      <span className="m-line f-s16_w700">大型望遠鏡</span>
                      です。主鏡は滑らかな１枚鏡で世界最大級です。その集光力は凄まじく、およそ27等星まで観測することができます。詳しくは、
                      <a
                        href="https://www.subarutelescope.org/jp/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        公式ホームページ
                      </a>
                      をご覧ください。
                    </p>
                  )}
                </div>
              </div>
              <div className="glossary_wrap" id="dic_4">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[3])}
                </p>
                <div className="glossary_wrap-text">
                  {i18n.language === 'en' ? (
                    <p className="m-indent">
                      Hyper Suprime-Cam (HSC) is
                      <span className="m-line f-s16_w700">
                        {' the ultra-wide field camera '}
                      </span>
                      mounted at the prime focus of the Subaru Telescope. The
                      telescope has the very wide field of view and great search
                      ability. It contributes to various researches in the
                      astronomy field since its joint use started at 2014. It
                      consists of a circular mosaic of 116 image sensors. The
                      diameter of the field of view is approximately 1.5 degrees
                      square (roughly the diameter of three full moons). For
                      more information, please refer to
                      <a
                        href="https://www.naoj.org/Projects/HSC/index.html"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {' the official website.'}
                      </a>
                    </p>
                  ) : (
                    <p className="m-indent">
                      Hyper
                      Suprime-Cam（HSC）は、すばる望遠鏡の主焦点に搭載された
                      <span className="m-line f-s16_w700">超広視野カメラ</span>
                      です。その視野の広さから、高い探索能力をもち、2014年に共同利用観測が開始されて以降、天文分野における数々の研究成果に貢献しています。116個のイメージセンサをモザイク状に組み合わせてできています。視野の直径は約1.5度角（およそ満月３個分）です。詳しくは、
                      <a
                        href="https://www.naoj.org/Projects/HSC/j_index.html"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        公式ホームページ
                      </a>
                      をご覧ください。
                    </p>
                  )}
                </div>
              </div>
              <div className="glossary_wrap" id="dic_5">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[4])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      '小惑星は、太陽のまわりを地球とは異なる速度で公転しているため、地球に対してみかけ上運動しています。そのため、恒星や銀河のような静止光源に対して時々刻々と位置が変化します。このような天体を「移動天体」と呼ぶことがあります。小惑星のみかけの移動速度が大きいと、露光中に光源が移動するため星像が移動方向に、カプセル形状に伸びます。COIASで小惑星を探す際に確認してみてください。',
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="dic_6">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[5])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      '光は、様々な波長を持っています。光の波長に関する情報は、その天体の色や組成などの情報を得るのに役立ちます。イメージセンサは光の波長を区別することができないため、波長に関する情報を得たい場合にはフィルターを用いて「ある波長域の光のみを集める」という操作が必要になります。HSCには様々な波長域のフィルターが用意されています。小惑星の観測でよく用いられるのはBroad-band filterと呼ばれるもので、g-band、r2-band、i2-band、z-band、y-bandの５種類があります。',
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="dic_7">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[6])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent mg-b_lg">
                    {t(
                      '天体の位置の表し方はいくつかありますが、COIASでは小惑星の位置を赤道座標系で表します。',
                    )}
                  </p>
                  <img
                    alt=""
                    src={HorizontalCoordinateSystem}
                    width="400px"
                    height="400px"
                    className="mg-b_xs"
                  />
                  <p className="mg-b_lg">
                    {t('（図：天文学辞典（日本天文学会）より）')}
                  </p>
                  <ul>
                    <li className="list_star">
                      <p className="f-s16_w700 m-letter_space">
                        {t('地平座標系')}
                      </p>
                      <p className="m-indent">
                        {t(
                          '地平座標系は、観測者を中心とした球面座標系で、天体の位置を高度（altitude）と方位（azimuth）により表します。高度は地平線からの天体の仰角で、地平線より上を正、下を負で表します。方位は北を0°とし、天頂方向を中心に東が90°、南が180°、西が270°として数えます。地平座標系は観測時には便利ですが、観測者ごとに座標系が異なります。異なる観測で得られたデータを比較する際には、観測者に依らない座標系が必要であるため、以下で説明する「赤道座標系」、「黄道座標系」がよく用いられます。',
                        )}
                      </p>
                    </li>
                    <li className="list_star">
                      <p className="f-s16_w700 m-letter_space">
                        {t('赤道座標系')}
                      </p>
                      <p className="m-indent">
                        {t(
                          '赤道座標系は、地心（地球の重心）を原点とする球面座標系で、天体の位置を赤経（right ascension: RA）と赤緯（declination: Dec）で表します。赤道座標系と天球との位置関係は地球が自転しても変わらないため、恒星や銀河の位置が決まった赤道座標で表せます。赤経は春分点方向を基準とした角度0°―360°を、360°=24時間として換算し、「時分秒」の単位で表すのが通例です。一方、赤緯は地球の赤道面と天球が交わる面を0とし、天の北極で+90°、天の南極で-90°となるようにとります。',
                        )}
                      </p>
                    </li>
                    <li className="list_star">
                      <p className="f-s16_w700 m-letter_space">
                        {t('黄道座標系')}
                      </p>
                      <p className="m-indent">
                        {t(
                          '黄道座標系は、天球上の太陽の軌道面（黄道）を基準にした球面座標系であり、黄経（longitude）と黄緯（latitude）で表します。黄経は赤経と同様に、春分点を原点としますが、通常は0°―360°の「度分秒」単位で表します。黄緯は黄道面を0とし、黄道面の北極で+90°、黄道面の南極で-90°となるようにとります。黄道は、地軸の傾きにより、天の赤道に対して約23.4°傾いています。小惑星は黄道の近くを探すと見つかりやすいです。',
                        )}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="glossary_wrap" id="dic_8">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[7])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t(
                      'COIASでは、赤道座標系など様々な情報が記録されたwarpという観測画像（通称warp画像）を用います。warp画像のファイル名は、「warp-HSC-[filter]-[tract]-[patch]-[visit].fits」という形式になっています。[filter]は観測に用いたフィルターの名称を表しています。[tract]は観測した天域を区分した際の番号となっています。[patch]は1つの[tract]の領域をさらに9x9に分割した時の1分割領域の番号を表します。[visit]は観測時刻に応じた固有の番号が入ります。',
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="dic_9">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[8])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t('異なる時刻で複数回撮影した同じ視野での観測画像を、')}
                    <span className="m-line f-s16_w700">
                      {t(
                        'パラパラ漫画のように順番に表示させることを「ブリンク」',
                      )}
                    </span>
                    {t(
                      'といいます。観測画像をブリンクさせることで、視野内にいる移動天体を直観的に発見しやすくなります。',
                    )}
                  </p>
                </div>
              </div>
              <div className="glossary_wrap" id="dic_10">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[9])}
                </p>
                <div className="glossary_wrap-text">
                  {i18n.language === 'en' ? (
                    <p className="m-indent">
                      MPC is abbreviation for
                      <span className="m-line f-s16_w700">
                        {' Minor Planet Center. '}
                      </span>
                      It is an international organisation that collects and
                      manages information on small solar-system objects,
                      calculates their orbits, and provides information and
                      services to aid observations. Information on asteroids
                      discovered by COIAS is reported to the MPC. For more
                      information, please see
                      <a
                        href="https://www.minorplanetcenter.net/iau/mpc.html"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {' the official website.'}
                      </a>
                    </p>
                  ) : (
                    <p className="m-indent">
                      MPCは、
                      <span className="m-line f-s16_w700">
                        Minor Planet Center（小惑星センター）
                      </span>
                      の略称です。太陽系小天体に関する情報を集めて管理したり、軌道を計算したり、観測の助けになる情報やサービスを提供したりしている国際的な機関です。COIASで発見した小惑星の情報は、MPCに報告されます。詳しくは、
                      <a
                        href="https://www.minorplanetcenter.net/iau/mpc.html"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        公式ホームページ
                      </a>
                      をご覧ください。
                    </p>
                  )}
                </div>
              </div>
              <div className="glossary_wrap" id="dic_11">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[10])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent mg-b_xs">
                    {t(
                      'COIASで測定した結果はMPC（Minor PlanetCenter）に報告されます。ここでは、MPCに報告するデータに何が書いてあるのか下の画像の例に沿って説明します。',
                    )}
                  </p>
                  <img alt="" src={MPCFormat} width="600px" height="100%" />
                  <ul className="text_lists">
                    <li className="mg-b_xs">
                      <p className="f-s16_w700">{t('ヘッダ情報部分')}</p>
                      <ul className="lists_indent">
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">COD</p>
                          <p className="m-indent">
                            {t(
                              '観測所に与えられた天文台コードです。すばる望遠鏡は2022年10月からT09というコードがMPCから割り当てられています。ちなみに以前は568でした。568はハワイ・マウナケア山頂にある多くの天文台をまとめて表す天文台コードでした。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">CON</p>
                          <p className="m-indent">
                            {t(
                              'MPCからの問い合わせ先です。COIAS開発チームの代表者が記載されています。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">OBS</p>
                          <p className="m-indent">
                            {t('観測者名です。COIASでは')}
                            <a
                              href="https://www2.nao.ac.jp/~masayuki/upload/hsc_pdr3_submitted.pdf"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {t('すばる望遠鏡のアーカイブデータに関する論文')}
                            </a>
                            {t(
                              'の著者名を記載しています。なお、この例はアーカイブデータを使っていない時の記載となっています。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">MEA</p>
                          <p className="m-indent">
                            {t('測定者名です。COIASで測定した')}
                            <span className="m-line f-s16_w700">
                              {t('皆様の名前を記載')}
                            </span>
                            {t('するところです。')}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">TEL</p>
                          <p className="m-indent">
                            {t(
                              '望遠鏡の情報です。F値(光学系の明るさ)が2.0で、口径8.2mの反射望遠鏡にHSCというCCDカメラで撮像したことが記載されています。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">NET</p>
                          <p className="m-indent">
                            {t('位置測定に使った星表カタログ名です。')}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">ACK</p>
                          <p className="m-indent">
                            {t(
                              'MPCへの連絡事項欄です。普段はSubaru/HSCのままで良いです。例えば、NEOやTNOといった珍しい天体の複数日以上の報告がある場合などはその旨を記載します。珍しい天体かなと思った場合は送信前にCOIAS開発チームにご連絡下さい。',
                            )}
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p className="f-s16_w700">{t('測定データ部分')}</p>
                      <p className="m-indent">
                        {t(
                          'ACKの下から始まる英数字の羅列が測定データです。測定データは1行80文字からなっています。何文字目に何を記載するか厳密に決められています。',
                        )}
                      </p>
                      <ul className="lists_indent">
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('1-5文字目')}
                          </p>
                          <p className="m-indent">
                            {t(
                              '確定番号です。5文字しかないので、例えば小惑星(100000)の記載は困ってしまいます。こう言う時は最初の2文字10はAと置き換えてA0000とします。以下、11はB、12はC・・・としていきます。この例の場合だと、k3930は小惑星（463930）を表しています。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('6-12文字目')}
                          </p>
                          <p className="m-indent">
                            {t(
                              'パーソナル符号、あるいは仮符号です。1900年代の仮符号天体、例えば1998 KY26は「J98K26Y」と表し、2000年代の仮符号天体2001 CC21はK01C21Cと表します。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('13文字目')}
                          </p>
                          <p className="m-indent">
                            {t(
                              '未発見天体の場合、その最初の時刻の報告に「*」マークをつけます。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('14文字目')}
                          </p>
                          <p className="m-indent">
                            {t(
                              '空欄です。MPCのために割り当てられています。報告する時は気にしなくて良いです。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('15文字目')}
                          </p>
                          <p className="m-indent">
                            {t(
                              '検出器（カメラ）の情報です。CCDカメラの場合は「C」となります。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('16-32文字目')}
                          </p>
                          <p className="m-indent">
                            {t(
                              '時刻情報です。この例だと、2017年7月23日の観測となります。時分秒は日の小数点で記載します。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('33-44文字目')}
                          </p>
                          <p className="m-indent">
                            {t('赤経の測定結果です。')}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('45-56文字目')}
                          </p>
                          <p className="m-indent">
                            {t('赤緯の測定結果です。')}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('57-65文字目')}
                          </p>
                          <p className="m-indent">{t('空欄です。')}</p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('66-71文字目')}
                          </p>
                          <p className="m-indent">
                            {t(
                              '等級（明るさ）の測定結果と使ったフィルター（観測波長）情報です。この例では、rバンドフィルターを使っています。',
                            )}
                          </p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('72-77文字目')}
                          </p>
                          <p className="m-indent">{t('空欄です。')}</p>
                        </li>
                        <li className="list_star">
                          <p className="f-s16_w700 m-letter_space">
                            {t('78-80文字目')}
                          </p>
                          <p className="m-indent">{t('天文台コードです。')}</p>
                        </li>
                      </ul>
                      <p className="m-indent">
                        {t(
                          'このデータは、MPCに報告すると同時に皆さんも手元にダウンロードして確認することができます。send_mpc.txtというファイル名になっています。さらに詳しくは',
                        )}
                        <a
                          href="https://www.minorplanetcenter.net/iau/info/PackedDes.html"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {t('こちら')}
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="glossary_wrap" id="dic_12">
                <p className="glossary_wrap-title m-letter_space">
                  {t(titles[11])}
                </p>
                <div className="glossary_wrap-text">
                  <p className="m-indent">
                    {t('スペースガードとは、')}
                    <span className="m-line f-s16_w700">
                      {t(
                        '天体（小惑星・彗星）の地球衝突から人類を守る様々な活動',
                      )}
                    </span>
                    {t(
                      'のことを指します。最近ではプラネタリーディフェンスとも言います。人類に被害をもたらすような天体衝突は全くありえないというわけではなく、2013年にロシア・チェリャビンスク州へ隕石が落下し衝撃波による被害が生じた事件は記憶に新しいです。さらに遡れば、約6600万年前にメキシコへ落下した巨大隕石は恐竜絶滅の原因であった可能性が高いとされています。こうした被害を防ぐため、スペースガードの分野では地球に衝突するかもしれないNEOを発見したり、衝突を防いだりする研究が行われています。',
                    )}
                  </p>
                </div>
              </div>
              {/* <div className="glossary_wrap" id="dic_13">
<p className="glossary_wrap-title m-letter_space">{titles[12]}</p>
<div className="glossary_wrap-text"><p className="m-indent"></p></div>
</div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dictionary;
