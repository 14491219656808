import React from 'react';

// components
import Footer from '../../components/Footer/Footer';

import Header from '../../components/Header/Header';

// plugin
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const FAQ = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title faq_title bg-color_transparent">
          <h3 className="f-page_title f-c_white f-en">FAQ</h3>
          <p className="f-c_white">{t('よくあるご質問')}</p>
        </div>
        <div className="u-contents_main bg-color_blue-white">
          <div className="contents_text faq_wrap">
            <div className="faq_contents">
              <p className="list_star">{t('このウェブサイトに関して')}</p>
              <Accordion>
                <Accordion.Item eventKey="1_1">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('COIASとはなんですか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'COIAS（Come On! Impacting ASteroids）は、小惑星をはじめとする未発見天体の探索を、どなたでも行っていただけるウェブアプリケーションです。さらに詳しくは、',
                      )}
                      <NavLink to="/about_coias">{t('COIASについて')}</NavLink>
                      {t('をご覧ください。')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1_2">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('COIASは誰でも使用できますか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'はい、どなたでもご利用いただけます。ご利用にあたっては、無料の会員登録を行っていただきます。その際、メールアドレスをご用意ください（ログインIDとして利用します）。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1_3">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('専門的な天文学の知識が必要ではないのですか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        '専門知識がなくてもご利用いただけます。COIASでは、専門知識が必要な部分の操作を自動化することで、一般の市民・学生の方が未発見天体を探索できるようになっています。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="1_4">
<Accordion.Header>
<p className="question_text"></p>
</Accordion.Header>
<Accordion.Body>
<p className="answer_text"></p>
</Accordion.Body>
</Accordion.Item> */}
              </Accordion>
            </div>
            <div className="faq_contents">
              <p className="list_star">{t('利用とログイン')}</p>
              <Accordion>
                <Accordion.Item eventKey="2_1">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('COIASは無料で使用できますか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'はい、無料でご利用いただけます。ただし、別途通信業者による通信料がかかる場合があります。COIASによる小惑星探索機能の利用中は複数の天体画像を取得しますので、モバイル回線など従量課金制の回線をお使いの場合は特にご注意ください。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2_2">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('推奨利用環境を教えてください。')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'COIASの説明用Webサイト（このページを含む、ログイン不要の部分）は、パソコン、タブレットなどのブラウザから閲覧可能です（環境により、多少体裁が崩れる可能性があります）。ログインが必要なウェブアプリケーション本体では、画像に写る細かい天体を見ながら作業していただくため、スマートフォンではなくパソコンで画面サイズを大きくしてご利用ください。画面の小さい環境ですと、表示が崩れるなどして正常にご利用いただけません。開発チームでは、OS:Windows10、Windows11及びmacOS、Webブラウザ: GoogleChrome最新版の環境で動作確認をしております。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2_3">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('スマートフォンで使用できますか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'COIASでは、画像に写る細かい天体を見ながら作業していただくため、スマートフォンなど画面の小さい環境に対応しておりません。パソコンからご利用いただくようにお願いします。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2_4">
                  <Accordion.Header>
                    <p className="question_text">{t('ログインできません。')}</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        '会員登録がお済みでない場合は、ログイン画面の「新規登録」画面から登録をお願い致します。パスワードをお忘れの場合は、ログイン画面の「パスワードをお忘れの方はこちら」からパスワードの再設定をお願い致します。',
                      )}
                      <br />
                      {t(
                        '「メールが確認されていません」と表示される場合、本登録がお済みでない可能性があります。入力したメールアドレスに届いた本登録メールをご確認ください。迷惑メールフォルダなどを確認してもメールが届いていないと思われる場合は、',
                      )}
                      <NavLink to="/contact">
                        {t('開発チームまでお問い合わせください。')}
                      </NavLink>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2_4_2">
                  <Accordion.Header>
                    <p className="question_text">
                      {t(
                        '新規登録後にログインボタンを押してもアプリが開きません。',
                      )}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'アプリは別タブで開きますが、ポップアップブロックによってタブが開かなかった可能性があります。開発チームの環境ではChromeブラウザならポップアップブロックされないことを確認しているため、まずはChromeの使用をお勧め致します。ポップアップブロックをされた場合、通常ブラウザの上部にその旨が通知されるため、その近くにある設定ボタンなどからポップアップを許可してください。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2_4_3">
                  <Accordion.Header>
                    <p className="question_text">
                      {t(
                        '本登録あるいはパスワード再設定時のメールが届きません。',
                      )}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'メールアドレスを打ち間違えている、または届いたメールが迷惑メール（スパム）として分類されている可能性があります。再度登録を試みるか、迷惑メールフォルダをご確認ください。迷惑メールフォルダなどを確認してもメールが届いていないと思われる場合は、',
                      )}
                      <NavLink to="/contact">
                        {t('開発チームまでお問い合わせください。')}
                      </NavLink>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2_5">
                  <Accordion.Header>
                    <p className="question_text">
                      {t(
                        'COIASのコンテンツ（画像など）を利用してもいいですか？',
                      )}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'COIAS開発チームが著作権を持つ著作物のうち、肖像権やパブリシティ権の影響がでない著作物であれば、広報普及活動など条件によってはご利用いただけます。商業活動・営利利用・その他の利用の場合はお問い合わせ下さい。詳細は、',
                      )}
                      <NavLink to="/regulation">{t('利用規程')}</NavLink>
                      {t('をご覧ください。')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="2_6">
<Accordion.Header>
<p className="question_text"></p>
</Accordion.Header>
<Accordion.Body>
<p className="answer_text"></p>
</Accordion.Body>
</Accordion.Item> */}
              </Accordion>
            </div>
            <div className="faq_contents">
              <p className="list_star">{t('運営と研究')}</p>
              <Accordion>
                <Accordion.Item eventKey="3_1">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('COIASは誰が運営していますか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'COIAS開発チーム（代表者：浦川聖太郎・日本スペースガード協会主任研究員）が運営しております。理念やチームメンバーについては',
                      )}
                      <NavLink to="/policy">{t('COIASのポリシー')}</NavLink>
                      {t('をご覧ください。')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3_2">
                  <Accordion.Header>
                    <p className="question_text">
                      {t(
                        'COIASを利用して得られた天体の情報はどのように利用されますか？',
                      )}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'まず、未発見の天体などに関するレポートは、小惑星など太陽系小天体の軌道情報を管理している国際的な機関MPC（Minor Planet Center）にて管理され、仮符号の付与や軌道の確定に利用されます（詳しくは、',
                      )}
                      <NavLink to="/details/procedure">
                        {t('小惑星を発見するには？')}
                      </NavLink>
                      {t(
                        'をご覧ください）。また、これまであまり見つかってこなかった小さな小惑星の位置に関する情報は、太陽系科学の基礎データとして重要な成果になることが期待されています。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="3_3">
<Accordion.Header>
<p className="question_text"></p>
</Accordion.Header>
<Accordion.Body>
<p className="answer_text"></p>
</Accordion.Body>
</Accordion.Item> */}
              </Accordion>
            </div>
            <div className="faq_contents">
              <p className="list_star">{t('未発見天体の報告')}</p>
              <Accordion>
                <Accordion.Item eventKey="4_1">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('COIASを使うと必ず小惑星を発見できますか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'COIASによってこれまで見つかりにくかった多数の小惑星を発見できることが期待されますが、個々の画像に必ず未発見の天体が含まれているわけではありません。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4_2">
                  <Accordion.Header>
                    <p className="question_text">
                      {t(
                        '報告した小惑星は、すべて新しい小惑星として認められますか？',
                      )}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'いいえ、その後の観測結果に応じて異なります。MPCによる「仮符号」の割り当てには、複数日以上の追観測結果が必要です。仮符号の取得までに時間を要する場合や、なかなか追観測ができず仮符号がつかないケースもありえます（さらに詳しくは、',
                      )}
                      <NavLink to="/details/procedure">
                        {t('小惑星を発見するには？')}
                      </NavLink>
                      {t('をご覧ください）。')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4_3">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('報告した小惑星には、必ず名前をつけられますか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'いいえ、前後に行われた観測や報告の状況によって異なります。COIASを使用して他の観測所より先に報告し、仮符号が付いた場合には、仮符号の付与に貢献したものとして扱われます。十分な追観測が行われて「確定番号」が付与されてはじめて、発見者に名前を提案する権利が与えられます。報告から命名が認められるまでには4～10年以上の長い期間がかかりますが、もしCOIASを利用して発見された小惑星への命名が可能となった場合は、利用者の方が名前を提案できるよう協力したいと考えております（さらに詳しくは、',
                      )}
                      <NavLink to="/details/procedure">
                        {t('小惑星を発見するには？')}
                      </NavLink>
                      {t('をご覧ください）。')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4_4">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('報告した天体のその後の状況を確認できますか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'ご自身でその後の状況を確認したい場合、MPCが公開している',
                      )}
                      <a
                        href="https://www.minorplanetcenter.net/wamo/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        "Where Are My Observations (WAMO)?"
                      </a>
                      {t(
                        'ページをご利用ください（リンク先は英語です）。レポートのsend_mpc.txtに記載されている、天体名から始まる80文字の行をページの枠に貼り付けてSubmitボタンを押すと結果が表示されます。また、数ヶ月おき程度の不定期で公開される「Minor Planet Circulars（小惑星回報）」ではCOIASで測定した結果がまとめて公表されます。COIAS開発チームとしても、小惑星回報からの情報を確認し次第Newsのページなどで状況をご報告してまいります。',
                      )}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="4_5">
<Accordion.Header>
<p className="question_text"></p>
</Accordion.Header>
<Accordion.Body>
<p className="answer_text"></p>
</Accordion.Body>
</Accordion.Item> */}
              </Accordion>
            </div>
            <div className="faq_contents">
              <p className="list_star">{t('天体画像データ')}</p>
              <Accordion>
                <Accordion.Item eventKey="5_1">
                  <Accordion.Header>
                    <p className="question_text">
                      {t('COIASで使用している画像データは何ですか？')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="answer_text">
                      {t(
                        'ハワイにある天文台「すばる望遠鏡」に搭載された高性能カメラ「ハイパー・シュプリーム・カム（HSC）」によって撮影されたデータです。HSCを使った大規模な観測プロジェクト「すばる戦略枠プログラム（SSP）」によって全世界に公開された画像を使用しています。SSPの画像は、国立天文台が運営する市民天文学プロジェクト',
                      )}
                      <a
                        href={
                          i18n.language === 'en'
                            ? 'https://galaxycruise.mtk.nao.ac.jp/en/'
                            : 'https://galaxycruise.mtk.nao.ac.jp/'
                        }
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Galaxy Cruise
                      </a>
                      {t('でも用いられています。')}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="5_2">
<Accordion.Header>
<p className="question_text"></p>
</Accordion.Header>
<Accordion.Body>
<p className="answer_text"></p>
</Accordion.Body>
</Accordion.Item> */}
              </Accordion>
            </div>
            {/* <div className="faq_contents">
<p className="list_star"></p>
<Accordion>
<Accordion.Item eventKey="6_1">
<Accordion.Header>
<p className="question_text"></p>
</Accordion.Header>
<Accordion.Body>
<p className="answer_text"></p>
</Accordion.Body>
</Accordion.Item>
</Accordion>
</div> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default FAQ;
