import React from 'react';

import './ModalStyle.scss';

// plugin
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const RegisterModal = ({ show, onHide }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('アカウント仮登録完了')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="contents-text_only">
          <span style={{ fontWeight: 'bold' }}>
            {t(
              'ご登録いただいたアドレスにメールをお送りしましたので、メールに記載のリンク(URL)をクリックして本登録をお願い致します。',
            )}
          </span>
          <span>
            {t(
              '本登録の後、ご登録いただいたアカウントでログインが可能になります。',
            )}
          </span>
          <p style={{ paddingTop: '10px', fontSize: '0.8em' }}>
            {t(
              'しばらく待ってもメールが届かない場合、入力いただいたメールアドレスが正しいか、迷惑メールフォルダに振り分けられていないか、などをお確かめ下さい。それでも届かない場合、お問い合わせフォームからご連絡いただくか、COIAS開発チームに直接メールにてご連絡下さい。',
            )}
          </p>
        </Modal.Body>
        <Modal.Footer className="one_button">
          <Button variant="secondary" onClick={onHide}>
            {t('閉じる')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegisterModal;
