import React from 'react';

// components
import Footer from '../components/Footer/Footer';

import Header from '../components/Header/Header';

import CharacterTalk from '../components/CharacterTalk/CharacterTalk';

// plugin
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

// image
import Icon from '../images/icon.png';
import { useTranslation } from 'react-i18next';

const AboutCOIAS = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">
            <span className="f-en">{t('COIASで小惑星を見つけよう！')}</span>
          </h3>
        </div>
        <div className="u-contents_main bg-color_blue-white">
          <CharacterTalk />
        </div>
        <div className="u-contents_main bg-color_blue-white">
          <h3 className="content_title f-c_primary">
            {i18n.language === 'en' ? (
              <>
                <span className="f-content_title">What is </span>
                <span className="f-s48_w700 f-en m-line">COIAS?</span>
              </>
            ) : (
              <>
                <span className="f-s48_w700 f-en m-line">COIAS</span>
                <span className="f-content_title">ってなに？</span>
              </>
            )}
          </h3>
          <img
            alt="icon"
            src={Icon}
            width="200px"
            height="200px"
            className="mg-b_md"
          />
          <div className="contents_text" style={{ marginBottom: '50px' }}>
            <p className="m-indent mg-b_xs">
              <span className="f-s16_w700 f-c_primary">
                COIAS(Come On! Impacting ASteroids)
              </span>
              {t(
                'は、小惑星をはじめとする未発見天体の探索を研究者と一般の市民・学生の方たちが協力して実施するために開発されたウェブアプリケーションです。COIASでは、すばる望遠鏡の画像データを用いることにより、まだ誰も見つけていない直径数100m程度の暗く小さな小惑星を見つけることが可能です。また、地球に近づくような危険な小惑星や海王星より遠方に存在する太陽系外縁天体を見つけることもできます。',
              )}
            </p>
            <p>{t('COIASを使って、様々な天体を見つけてみましょう。')}</p>
          </div>
          <Button
            className="f-button large_btn"
            variant="primary"
            onClick={() => {
              navigate('/login');
            }}
          >
            {t('使ってみる')}
          </Button>
        </div>
        <div className="u-contents_main bg-color_white">
          <h3 className="content_title f-content_title f-c_primary">
            <span className="f-en">{t('COIASが目指すもの')}</span>
          </h3>
          <ul className="contents_text">
            <li className="list_content about_coias">
              <p className="f-s20_w500 list_content-title">
                {t('太陽系の地図作り')}
              </p>
              <p className="m-indent list_content-explanation">
                {t(
                  '1801年に初めて小惑星が発見されて以来、これまで100万個を超える小惑星が見つかっています。しかし、直径数100m程度の小さな小惑星はあまり見つかっておらず、そうした小惑星が太陽系のどこにどれくらいいるか分かっていません。COIASを使って未発見小惑星を見つけると、このような情報（軌道分布）がわかるようになります。',
                )}
                <span className="f-s16_w700 m-line">
                  {t('いわば、太陽系の地図作りです。')}
                </span>
                {t('この地図は太陽系科学の大切な基礎データとなります。')}
                <br />
              </p>
            </li>
            <li className="list_content about_coias">
              <p className="f-s20_w500 list_content-title">
                {t('プラネタリーディフェンス(スペースガード)')}
              </p>
              <p className="m-indent list_content-explanation">
                {t(
                  'プラネタリーディフェンス(あるいはスペースガード)とは、小惑星の地球衝突から人類を守る様々な活動のことを指します。特に、観測の分野では、世界中の望遠鏡が協力して地球に近づく軌道を持つ',
                )}
                <span className="f-s16_w700 m-line">
                  {t('地球接近天体（NEO：near-Earth objects）')}
                </span>
                {t(
                  'と呼ばれる小惑星を探しています。COIASで見つかる小惑星には地球接近天体も含まれます。COIASによってプラネタリーディフェンス(スペースガード)に貢献できます。',
                )}
              </p>
            </li>
            <li className="list_content about_coias">
              <p className="f-s20_w500 list_content-title">
                {t('太陽系外縁天体・彗星・さらなる未知な天体や現象')}
              </p>
              <p className="m-indent list_content-explanation">
                {t(
                  '小惑星以外にも様々な天体が見つかるかもしれません。海王星より遠くに存在する太陽系外縁天体や綺麗な尾をもつ彗星も見つかるかもしれません。まだ数例しか観測例のない、太陽系の外からやってくる恒星間天体が見つかれば大発見になるでしょう。とても可能性は低いですが第9惑星が見つかれば、歴史に残る大発見となります。さらには、これらの天体以外の私たちが全く想像もしていなかった未知の天体や現象が見つかるかもしれません。',
                )}
              </p>
            </li>
            <li className="list_content about_coias">
              <p className="f-s20_w500 list_content-title">{t('市民天文学')}</p>
              <p className="m-indent list_content-explanation">
                {t(
                  '科学研究に広く一般市民が参加する、市民科学（ =シチズンサイエンス）という取り組みがあります。「市民天文学」とは、天文学に関する市民科学の呼び名として国立天文台が提唱しているものです。皆さんがCOIASを使うことで、天文学の魅力を体感しながら、実際の研究データ収集に貢献できます。特に、COIASでは、皆さんが測定結果を国際的な機関（MPC：Minor Planet Center）に報告する機能を有しています。COIASによって研究者と完全に同じルール・基準での研究体験を得ることができます。',
                )}
              </p>
            </li>
            {/* <li className="list_content about_coias">
<p className="f-s20_w500 list_content-title"></p>
<p className="m-indent list_content-explanation"></p>
</li> */}
          </ul>
          <div className="detail_buttons_wrap">
            <p className="description f-s16_w700">
              {t('このWebサイトについてもっと知りたい方はこちら')}
            </p>
            <div className="btn_wrap">
              <Button
                className="f-button large_btn"
                variant="secondary"
                onClick={() => navigate('/details/procedure')}
              >
                {t('小惑星を発見するには？')}
              </Button>
              <Button
                className="f-button large_btn"
                variant="secondary"
                onClick={() => navigate('/details/dictionary')}
              >
                {t('用語解説集')}
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutCOIAS;
