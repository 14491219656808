import React from 'react';

// components
import Footer from '../components/Footer/Footer';

import Header from '../components/Header/Header';
import { useTranslation } from 'react-i18next';

const Regulation = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('利用規程')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <ul style={{ marginBottom: '50px' }}>
              <li className="list_content regulation">
                <p>{t('1. はじめに')}</p>
                <p>
                  {t(
                    '　COIAS開発チームが運営する「COIAS」のウェブページ（以下「本サイト」）をご利用される際には、ここに定める条件を遵守していただくことをお願いしています。利用者各位におかれましては、本サイトを閲覧、ダウンロード、リンク、転載、その他の行為によりご利用される場合には、この利用規約に同意したものとみなします。また、本利用規約の内容は、予告なしに見直しおよび変更をさせていただくことがあります。本利用規約を変更した場合は、新しい規約を順守していただきます。更新内容は本サイト上に提示し、お知らせするものとします。',
                  )}
                </p>
              </li>
              <li className="list_content regulation">
                <p>{t('2. 免責事項')}</p>
                <p>
                  {t(
                    '　本サイトについては、細心の注意を払って管理・運営を行なっていますが、利用者が本サイト及び本サイトの情報を利用することによって生じるいかなる損害についても、COIAS開発チームは一切の責任を負うものではありません。COIAS開発チームは、予告なしに本サイト上の情報を変更・削除したり、本サイトの運用そのものを中断・中止することがありますが、これによって生じる利用者のいかなる損害についても、責任を負うものではないこととします。あらかじめ、ご了承ください。また、便利のためにウェブサーバーを設置している会津大学以外の機関や団体のサイト（以下、外部サイト）に対してリンクを設けている箇所があります。リンク先にある外部サイトの利用については、外部サイトの利用規程（サイトポリシー）が適用されます。',
                  )}
                </p>
              </li>
              <li className="list_content regulation">
                <p>{t('3. 著作権')}</p>
                <p>
                  {t(
                    '　本サイト全体、及び本サイトに掲載されているすべての著作物（COIASアプリケーション本体、テキスト、写真、イラスト、映像、音声等）は原則としてCOIAS開発チーム、もしくは各著作権者が著作権を有しており、著作権法及び国際条約により保護されています。著作物の利用方法は以下となります。',
                  )}
                </p>
                <br />
                <ul className="list_ml">
                  <li className="list_mb">
                    <p>
                      {t(
                        '3-1.COIAS開発チームが著作権を持つ著作物のうち、肖像権やパブリシティ権の影響がでない著作物であれば、以下の範囲において利用することができます。',
                      )}
                    </p>
                    <ul className="list_ml">
                      <li className="list_star">
                        {t(
                          '天文学・惑星科学・科学一般の広報普及活動のための利用',
                        )}
                      </li>
                      <li className="list_star">
                        {t('学術研究・教育活動・学習活動のための利用')}
                      </li>
                      <li className="list_star">
                        {t('報道機関における放送、出版、配信のための利用')}
                      </li>
                      <li className="list_star">
                        {t('博物館等の展示、広報活動')}
                      </li>
                      <li className="list_star">
                        {t(
                          'ウェブサイト、ソーシャルネットワーク、アプリにおける情報発信',
                        )}
                      </li>
                      <li className="list_star">
                        {t('漫画、文芸、芸術等作品の素材')}
                      </li>
                    </ul>
                    <p>
                      {t(
                        'ただし、COIASがどのように使われているか開発チームとしても興味があります。COIASを用いて学術研究を除く上記の学術活動等を行う際はCOIAS開発チームに事前にお知らせいただけると嬉しいです。',
                      )}
                    </p>
                    <p>
                      {t(
                        '上記の活動等以外の商業活動・営利利用・その他の利用の場合はお問い合わせ下さい。',
                      )}
                    </p>
                  </li>
                  <li>
                    <p>{t('3-2. 他機関、他者の著作物')}</p>
                    <p>
                      {t(
                        '他機関（本サイトの場合、主に国立天文台）もしくは他者（本サイトの場合、主にQuro・芳文社/星咲高校地学部）の著作物は、それぞれの機関、他者が著作権を有しています。日本の著作権法が定める自由利用の範囲を超えて利用する場合は、該当する機関もしくは該当する個人等へ直接お問い合わせ下さい。',
                      )}
                    </p>
                  </li>
                </ul>
              </li>
              <li className="list_content regulation">
                <p>{t('4. 個人情報の収集に関する取り扱い')}</p>
                <p>
                  {t(
                    '　本サイトでは、利用登録やお問い合わせフォーム等において、必要最小限の個人情報（氏名、ニックネーム、メールアドレス、測定者の氏名の英語表記）を収集します。収集した個人情報は、当該サービス等の目的を達成するために使用し、それ以外の目的には使用しません。また、合理的な手段を用いて適切かつ安全に管理します。目的の達成後は、必要な保管期限が経過した後に削除します。また、本サイトが収集した個人情報は下記の場合を除いては、原則として第三者に開示または提供しません。',
                  )}
                </p>
                <ul className="list_ml">
                  <li className="list_star">
                    {t(
                      '利用者の同意がある場合。特に、COIASでは測定者名(実名の英語表記)を記入した上で、観測結果をMPCに報告する必要があります。本サイトでは、送信ボタンを押した時点で利用者の同意が得られたこととします。',
                    )}
                  </li>
                  <li className="list_star">
                    {t('法的根拠に基づいて請求された場合')}
                  </li>
                  <li className="list_star">
                    {t(
                      '人の生命、身体もしくは財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき',
                    )}
                  </li>
                  <li className="list_star">
                    {t(
                      '公衆衛生の向上もしくは児童の健全な育成の推進のために特に必要である場合であって、本人の同意を得ることが困難であるとき',
                    )}
                  </li>
                  <li className="list_star">
                    {t(
                      '国の機関もしくは地方公共団体、あるいはその委託を受けた者が、法令の定める事務を遂行することに対して協力する場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき',
                    )}
                  </li>
                  <li className="list_star">
                    {t(
                      'COIAS開発チームは、利用者本人から、法令の定めに基づき個人情報の開示、訂正、利用停止、消去を求められれたときは、遅延なく対応するものとします。',
                    )}
                  </li>
                </ul>
              </li>
              <li className="list_content regulation">
                <p>{t('5. COIASを用いた研究成果')}</p>
                {i18n.language === 'en' ? (
                  <p>
                    If you use this website and publish the results as academic
                    research (research presentations and papers), we would
                    appreciate it if you include the notation of
                    "COIAS(https://web-coias.u-aizu.ac.jp/)" in the
                    acknowledgements. We would also appreciate it if you could
                    inform us after the publication of your paper.
                  </p>
                ) : (
                  <p>
                    　本サイトを利用した上でその成果を学術研究(研究発表及び論文)として発表される場合は、謝辞に「COIAS(https://web-coias.u-aizu.ac.jp/)」の表記を含めていただけますと幸いです。また、論文出版後にご一報いただきますようお願いします。
                  </p>
                )}
              </li>
              <li className="list_content regulation">
                <p>{t('6. 本サイトへのリンクについて')}</p>
                <p>
                  {t(
                    '　本サイトへのリンクの設定は自由に行うことができます。ただし、以下の行為を禁止します。',
                  )}
                </p>
                <ul className="list_ml">
                  <li className="list_star">
                    {t(
                      '公序良俗に反する目的・態様によるウェブサイトからのリンク行為',
                    )}
                  </li>
                  <li className="list_star">
                    {t(
                      '特定の団体・個人に対する誤解を与えたり、イメージや信用度を著しく低下させたりする恐れのあるリンク行為',
                    )}
                  </li>
                  <li className="list_star">
                    {t(
                      '本サイトのコンテンツがリンク元のウェブサイトの一部に見えるような形のリンク行為',
                    )}
                  </li>
                </ul>
              </li>
              <li className="list_content regulation">
                <p>{t('7. 不正な利用について')}</p>
                <p>
                  {t(
                    '　利用者が本規定に定める各事項を遵守した利用を行なっていないとCOIAS開発チームが判断した場合は、不正な利用に対して、当該利用を差し止める権利、かつ損害賠償を求める権利を有するものとします。',
                  )}
                </p>
              </li>
              {/* <li className="list_content regulation">
<p>{t('タイトル')}</p>
<p>内容</p>
</li> */}
            </ul>
            <p style={{ textAlign: 'end' }}>{t('2023年5月5日')}</p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Regulation;
