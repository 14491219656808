import React from 'react';
import { useTranslation } from 'react-i18next';
import { numberedObjectsInfoList } from './NumberedObjectsInfoList';

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const numberedObjectsInfoListSortedByNo = numberedObjectsInfoList.sort(
  (a, b) => {
    // (no) -> no
    const aNum = parseInt(a.number.replace('(', '').replace(')', ''));
    const bNum = parseInt(b.number.replace('(', '').replace(')', ''));
    return aNum - bNum;
  },
);
const numberedObjectsInfoListForTable =
  numberedObjectsInfoListSortedByNo.flatMap((item, index) => ({
    ...item,
    userNames: item.detail.map((d) => d.userName).join(', '),
    id: index + 1,
  }));

function NumberedObjectsInformationTable() {
  const { t } = useTranslation();
  const expandRow = {
    renderer: (row) => {
      const orbitInfoStrArr = [];
      orbitInfoStrArr.push(`H=${row.absoluteMagnitude.toFixed(1)}mag`);
      orbitInfoStrArr.push(`a=${row.semimajorAxis.toFixed(1)}au`);
      orbitInfoStrArr.push(`e=${row.eccentricity.toFixed(2)}`);
      orbitInfoStrArr.push(`i=${row.inclination.toFixed(1)}deg`);

      return (
        <div>
          {row.detail.map((d) => (
            <p>
              {d.userName} {t('さんの測定時点での天体名')}: {d.HNames}
            </p>
          ))}
          <p>{`(${orbitInfoStrArr.join(', ')})`}</p>
        </div>
      );
    },
    showExpandColumn: true,
  };

  function NumberedObjectsFormatter(cell, _) {
    return (
      <a
        href={`https://www.minorplanetcenter.net/db_search/show_object?object_id=${cell.replace(
          ' ',
          '+',
        )}`}
        style={{ textDecoration: 'underline' }}
        target="_blank"
        rel="noreferrer noopener"
      >
        {cell}
      </a>
    );
  }

  return (
    <div className="data_analysis_provisional_table">
      <BootstrapTable
        keyField="id"
        data={numberedObjectsInfoListForTable}
        columns={[
          {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: (_) => ({ width: '50px' }),
          },
          {
            dataField: 'number',
            text: t('確定番号'),
            sort: true,
            formatter: NumberedObjectsFormatter,
            headerStyle: (_) => ({ width: '95px' }),
          },
          {
            dataField: 'name',
            text: t('名称'),
            sort: true,
            headerStyle: (_) => ({ width: '75px' }),
          },
          {
            dataField: 'userNames',
            text: t('測定者（敬称略）'),
            filter: textFilter(),
            sort: true,
          },
        ]}
        filter={filterFactory()}
        pagination={paginationFactory({
          sizePerPageList: [
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
            { text: '200', value: 200 },
          ],
        })}
        expandRow={expandRow}
        striped
      />
    </div>
  );
}

export default NumberedObjectsInformationTable;
