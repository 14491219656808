import React, { useState } from 'react';

import './FooterStyle.scss';

// components
import CreditModal from '../Modal/CreditModal';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  const [creditModalShow, setCreditModalShow] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="footer bg-color_primary">
      <div className="footer-title f-en">
        <p className="f-c_white f-s14_w500">Come On! Impacting ASteroids</p>
        <p className="f-c_white f-s24_w700">COIAS</p>
      </div>
      <div className="footer_links">
        <div className="link_module">
          <div className="link_circle" />
          <span onClick={() => setCreditModalShow(true)}>
            {t('クレジット')}
          </span>
        </div>
        <div className="link_module">
          <div className="link_circle" />
          <NavLink to="/policy">
            <span className="f-en f-s16_w500">{t('COIASのポリシー')}</span>
          </NavLink>
        </div>
        <div className="link_module">
          <div className="link_circle" />
          <NavLink to="/regulation">{t('利用規程')}</NavLink>
        </div>
        <div className="link_module">
          <div className="link_circle" />
          <a
            href="http://koiastv.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('恋する小惑星TVアニメ公式HP')}
          </a>
        </div>
      </div>
      <CreditModal
        show={creditModalShow}
        onHide={() => setCreditModalShow(false)}
        title="クレジット"
      />
    </div>
  );
};

export default Footer;
