import React, { useEffect, useState } from 'react';

import axios from 'axios';

// components
import Footer from '../../components/Footer/Footer';

import Header from '../../components/Header/Header';

import BarChart from '../../components/BarChart';
import { useTranslation } from 'react-i18next';

const apiURL = process.env.REACT_APP_API_URL;

const Candidates = () => {
  const { t } = useTranslation();

  const [rankingData, setRankingData] = useState([]);
  const [rankingWeeklyData, setRankingWeeklyData] = useState([]);

  const [NNewObject, setNNewObject] = useState(-1);

  const getRanking = () => {
    axios
      .get(`${apiURL}/measure_ranking`)
      .then((res) => {
        const rankingData = res.data.result;

        if (rankingData) {
          setRankingData(rankingData);
        }
      })
      .catch((e) => console.log(e));
  };

  const getRankingWeekly = () => {
    axios
      .get(`${apiURL}/measure_ranking?is_weekly=True`)
      .then((res) => {
        const rankingWeeklyData = res.data.result;

        if (rankingWeeklyData) {
          setRankingWeeklyData(rankingWeeklyData);
        }
      })
      .catch((e) => console.log(e));
  };

  const getObjectsFigure = () => {
    axios
      .get(`${apiURL}/N_new_objects`)
      .then((res) => {
        const N_new_objects = res.data.result;

        if (N_new_objects) {
          setNNewObject(N_new_objects);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getRanking();
    getRankingWeekly();
    getObjectsFigure();
  }, []);

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('新天体の候補')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <div className="data_analysis">
              <p>
                {t(
                  '新天体候補とは、未発見の可能性がある天体としてCOIASから小惑星センター（MPC）に報告された天体です。',
                )}
                {t(
                  '複数日の観測データが集まると、MPCから仮符号が割り当てられます。',
                )}
              </p>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで現在までに発見された新天体候補数')}
              </p>
              <p className="number_wrap">
                <span className="number">{NNewObject}</span>
                <span className="unit">{t('個')}</span>
              </p>
            </div>
            <div className="data_analysis">
              <span className="mg-r_xs f-s14_w500">
                {t(
                  '(注) 本ページの以下に記載しているデータの更新は1日おきです。リアルタイムの数字ではないためご注意ください。',
                )}
              </span>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">{t('測定天体数ランキング')}</p>
              <BarChart rankingData={rankingData} />
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('直近1週間の測定天体数ランキング')}
              </p>
              <BarChart rankingData={rankingWeeklyData} />
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで発見された新天体候補数の履歴')}
              </p>
              <img
                src="https://web-coias.u-aizu.ac.jp/images/new_object_history.png"
                alt=""
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Candidates;
