import React, { useEffect, useState } from 'react';

// components
import Footer from '../../components/Footer/Footer';

import Header from '../../components/Header/Header';

import ProvisionalCodeInformationTable from '../../components/ProvisionalCodeInformationTable/ProvisionalCodeInformationTable';
import { provisionalCodeInfoList } from '../../components/ProvisionalCodeInformationTable/provisionalCodeInfoList';
import OrbitInfoScatterPlot from '../../components/OrbitInfoScatterPlot/OrbitInfoScatterPlot';
import { useTranslation } from 'react-i18next';

const Provisional = () => {
  const { t } = useTranslation();
  const [NProvisionalCode, setNProvisionalCode] = useState(-1);
  const getCounts = () => {
    setNProvisionalCode(provisionalCodeInfoList.length);
  };
  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('仮符号天体の詳細')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <div className="data_analysis">
              <p className="data_analysis-title">{t('仮符号天体')}</p>
              <p className="number_wrap">
                <span className="number">{NProvisionalCode}</span>
                <span className="unit">{t('個')}</span>
              </p>
              <div className="mg-t_sm">
                <p className="f-s14_w500">
                  {t(
                    '調査の結果COIAS以前に発見された天体と同定されたものや、他の観測所の発見と判断されたものも含む。',
                  )}
                </p>
              </div>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで発見された仮符号天体のリスト')}
              </p>
              <ProvisionalCodeInformationTable />
              <br />
              <span className="mg-r_xs f-s14_w500">
                {t(
                  '(注) このリストでは情報の重複表示を避けるため、COIAS内で取得した仮符号同士が同じものと同定された場合、1つのみを残して他は記載しておりません。',
                )}
              </span>
            </div>
            <div className="data_analysis">
              <p className="data_analysis-title">
                {t('COIASで発見された仮符号天体の軌道情報')}
              </p>
              <OrbitInfoScatterPlot />
              <br />
              <span className="mg-r_xs f-s14_w500 glossary_wrap-text">
                {t(
                  'データ点にカーソルをあわせると各仮符号天体の軌道詳細が表示されます。スクロールとグラブでグラフの描画範囲を変更できます。',
                )}
              </span>
              <br />
              <span className="mg-r_xs f-s14_w500">
                {t('(注) 直径の見積もりには反射率0.15を仮定しています。')}
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Provisional;
