import React from 'react';

import './ModalStyle.scss';

// plugin
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CreditModal = ({ show, onHide }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('クレジット')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="contents-text_only">
          <ul>
            <li className="list_star">
              <p>{t('アイコンillustration')}</p>
              <p>©{t('Quro/芳文社')}</p>
            </li>
            <li className="list_star">
              <p>{t('吹き出しイラスト')}</p>
              <p>{t('©Quro・芳文社/星咲高校地学部')}</p>
            </li>
            <li className="list_star">
              <p>{t('解析画像データ')}</p>
              <a
                href="https://hsc-release.mtk.nao.ac.jp/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {t(
                  '国立天文台すばる望遠鏡HSCすばる戦略枠プログラムが一次配布者として処理を行ったデータを使用しています。',
                )}
              </a>
            </li>
            <li className="list_star">
              <p>{t('画像選択モードにおけるHSCの画像')}</p>
              <a
                href="https://www.naoj.org/Projects/HSC/"
                target="_blank"
                rel="noreferrer noopener"
              >
                The HSC project
              </a>
            </li>
            <li className="list_star">
              <p>{t('ヒッパルコス星表カタログ')}</p>
              <a
                href="https://www.cosmos.esa.int/web/hipparcos/hipparcos-2"
                target="_blank"
                rel="noreferrer noopener"
              >
                THE HIPPARCOS-2 CATALOGUE by the EUROPEAN SPACE AGENCY
              </a>
            </li>
            <li className="list_star">
              <p>{t('天の川の背景画像')}</p>
              <a
                href="https://www.eso.org/public/images/eso0932a/"
                target="_blank"
                rel="noreferrer noopener"
              >
                The Milky Way panorama by ESO / S.Brunier
              </a>
            </li>
            <li className="list_star">
              <p>{t('参考文献')}</p>
              <p>{t('「スペースガード探偵団」、高橋典嗣・吉川真監修、NPO法人日本スペースガード協会編、アプリコット出版')}</p>
            </li>
            <li className="list_star">
              {t(
                '本研究（アプリ開発及びその結果得られる科学成果）は会津大学宇宙情報科学研究センター公募型共同研究（文部科学省特色ある共同研究拠点の整備の推進事業JPMXP0619217839及びJPMXP0622717003）の助成を受けたものです。',
              )}
            </li>
            <li className="list_star">
              {t(
                '本研究（アプリ開発に至るまでの基礎研究・アプリ開発及びその結果得られる科学成果）は科研費JP16K05310,JP20K0401の助成を受けたものです。',
              )}
            </li>
            {/* <li className="list_star">
<p>{t('テキスト')}</p>
<a href="{t('リンク')}" target="_blank" rel="noreferrer noopener">
{t('リンク')}
</a>
</li> */}
          </ul>
        </Modal.Body>
        <Modal.Footer className="one_button">
          <Button variant="secondary" onClick={onHide}>
            {t('閉じる')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreditModal;
