import React from 'react';

// plugin
import { Table } from 'react-bootstrap';

// components
import Footer from '../components/Footer/Footer';

import Header from '../components/Header/Header';
import { useTranslation } from 'react-i18next';

const Policy = () => {
  const { t } = useTranslation();

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">
            <span className="f-en">{t('COIASのポリシー')}</span>
          </h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <div className="contents_text">
            <div style={{ marginBottom: '50px' }}>
              <p className="m-indent">
                {t(
                  'COIASは、小惑星の位置測定と測光を行うための研究を目的としたアプリです。COIASで得られた科学データは、COIAS開発チームによる研究活動に用います。COIAS開発チーム以外の研究者の方は、各人で解析したデータに関して自由にお使いいただけます。COIASは、研究者と同じ基準・ルールで研究ができる環境を広く一般に提供することを一つのコンセプトとしています。COIASにより、子供・学生だけでなく多くの方々に本物のデータを用いた研究体験をしていただき、科学の裾野を広げることを目的としています。',
                )}
              </p>
              <p className="m-indent">
                {t(
                  'COIASはCOIAS開発チーム代表・浦川聖太郎の個人研究から始まり、共同研究者とともに発展してきたものです。会津大学宇宙情報科学研究センターと科研費(科学研究費助成事業)の補助を受けて研究開発を行ってきましたが、国立天文台、JAXA、日本スペースガード協会、開発メンバーの所属大学といった組織、団体により運営されているものではありません。COIASの開発・運用・保守はCOIAS開発チームがボランティアベースで行っています。そのため、市販のアプリケーションのような即座の不具合対応ができない場合があります。ご了承ください。',
                )}
              </p>
              <p className="m-indent">
                {t(
                  'COIAS開発チームに入りたい方、開発・運用・資金面でご支援いただける個人・団体・企業様、教育事業等でCOIASを利用したい方、取材等をされたい方を歓迎致します。COIASに興味を持たれた方はCOIAS開発チーム(coias-dev[_at_]u-aizu.ac.jp, [_at_]は@に変換)までご連絡ください。',
                )}
              </p>
            </div>
            <div style={{ marginBottom: '50px' }}>
              <p style={{ marginBottom: '10px' }}>{t('COIAS開発チーム')}</p>
              <Table striped="columns">
                <tbody>
                  <tr>
                    <th>{t('代表')}</th>
                    <td>
                      {t('浦川聖太郎（')}
                      <a
                        href="https://www.spaceguard.or.jp/html/ja/index.html"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {t('NPO法人日本スペースガード協会')}
                      </a>
                      {t('主任研究員）')}
                    </td>
                  </tr>
                  <tr>
                    <th>{t('プログラム開発リーダー')}</th>
                    <td>{t('杉浦圭祐（東京工業大学　研究員）')}</td>
                  </tr>
                  <tr>
                    <th>{t('hscMap・プログラム開発')}</th>
                    <td>{t('小池美知太郎（国立天文台　研究技師）')}</td>
                  </tr>
                  <tr>
                    <th>{t('用語集作成・プログラム開発')}</th>
                    <td>{t('前田夏穂（神戸大学　博士後期課程大学院生）')}</td>
                  </tr>
                  <tr>
                    <th>{t('ウェブコンテンツ作成・ユーザー体験検討')}</th>
                    <td>{t('伊東健一（東京大学　博士後期課程大学院生）')}</td>
                  </tr>
                  <tr>
                    <th>{t('ウェブコンテンツ作成・ユーザー体験検討')}</th>
                    <td>{t('宇野慎介（東京大学　博士後期課程大学院生）')}</td>
                  </tr>
                  <tr>
                    <th>{t('アーカイブデータアドバイザー')}</th>
                    <td>{t('大坪貴文（国立天文台　特任研究員）')}</td>
                  </tr>
                  <tr>
                    <th>{t('サーバー管理及び設定')}</th>
                    <td>{t('北里宏平（会津大学　准教授）')}</td>
                  </tr>
                  <tr>
                    <th>{t('サーバー管理及び設定・ユーザビリティ確認')}</th>
                    <td>{t('服部晃大（会津大学　博士前期課程大学院生）')}</td>
                  </tr>
                  <tr>
                    <th>{t('ユーザビリティ確認・研究アドバイザー')}</th>
                    <td>{t('関口朋彦（北海道教育大学　教授）')}</td>
                  </tr>
                  <tr>
                    <th>{t('開発体制アドバイザー')}</th>
                    <td>{t('木下大輔（台湾国立中央大学　教授）')}</td>
                  </tr>
                  <tr>
                    <th>{t('シチズンサイエンスアドバイザー')}</th>
                    <td>
                      {t('臼田-佐藤功美子（国立天文台　広報普及専門員）')}
                    </td>
                  </tr>
                  {/* <tr>
<th>役職</th>
<td>名前（所属）</td>
</tr> */}
                </tbody>
              </Table>
              <p style={{ textAlign: 'end' }}>{t('所属は2023年3月末時点')}</p>
            </div>
            <div className="cooperate">
              <a
                href="https://www.aizulab.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {t('開発協力：株式会社会津ラボ')}
              </a>
              <p>{t('デザイン協力：Quro, 芳文社, 星咲高校地学部')}</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Policy;
