import React, { useState } from 'react';

// plugin
import { Button, Form } from 'react-bootstrap';

// components
import Footer from '../components/Footer/Footer';

import Header from '../components/Header/Header';

import SendCompletelyModal from '../components/Modal/SendCompletelyModal';
import { useTranslation } from 'react-i18next';

import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

const Contact = () => {
  const [sendCompletelyModalShow, setSendCompletelyModalShow] = useState(false);
  const [userName, setUserName] = useState('');
  const [eMail, setEMail] = useState('');
  const [contents, setContents] = useState('');
  const { t } = useTranslation();
  const [submitDisable, setSubmitDisable] = useState(true);
  const SITE_KEY = process.env.REACT_APP_SITE_KEY;
  const API_URI = process.env.REACT_APP_API_URL;
  const GOOGLE_FORM_URL = process.env.REACT_APP_GOOGLE_FORM_URL;

  const onChange = (value) => {
    verifyRecaptchaToken(value);
  };

  const verifyRecaptchaToken = async (token) => {
    await axios
      .post(`${API_URI}/verify_recaptcha_token`, { token: token })
      .then(() => {
        setSubmitDisable(false);
      })
      .catch((e) => {
        alert(
          t('recaptchaの情報が認証されませんでした。もう一度お試しください。'),
        );
      });
  };

  return (
    <div className="l-page bg-img">
      <Header />
      <div className="page_wrap">
        <div className="u-page_sub_title bg-color_transparent">
          <h3 className="f-page_title f-c_white">{t('お問い合わせ')}</h3>
        </div>
        <div className="u-contents_main bg-color_white">
          <Form
            className="contact_wrap"
            target="dummyIframe"
            action={GOOGLE_FORM_URL}
            onSubmit={async (e) => {
              const inputValue = {
                name: e.target[0].value,
                email: e.target[1].value,
                message: e.target[2].value,
              };
              await axios
                .put(`${API_URI}/send_contact_message`, inputValue)
                .then(() => {
                  setSendCompletelyModalShow(true);
                  setUserName('');
                  setEMail('');
                  setContents('');
                })
                .catch((e) => {
                  alert(
                    t(
                      'お問い合わせの送信に失敗しました。何回かやり直しても失敗する場合、お手数ですがCOIAS開発チームまで直接メールをお願い致します。',
                    ),
                  );
                });
            }}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                <span className="mg-r_xs f-s16_w700">{t('お名前')}</span>
                <span className="f-c_primary">{t('(必須)')}</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder={t('山田　太郎')}
                name="entry.1087139111"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                <span className="mg-r_xs f-s16_w700">
                  {t('メールアドレス')}
                </span>
                <span className="f-c_primary">{t('(必須)')}</span>
              </Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="xxxxxxxxxx@gmail.com"
                name="entry.2020032862"
                value={eMail}
                onChange={(e) => {
                  setEMail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>
                <span className="mg-r_xs f-s16_w700">
                  {t('お問い合わせ内容')}
                </span>
                <span className="f-c_primary">{t('(必須)')}</span>
              </Form.Label>
              <Form.Control
                required
                className="contact_textarea"
                as="textarea"
                rows={3}
                name="entry.1666262014"
                value={contents}
                onChange={(e) => {
                  setContents(e.target.value);
                }}
              />
            </Form.Group>
            <ReCAPTCHA
              style={{ margin: '10px 0' }}
              sitekey={SITE_KEY}
              onChange={onChange}
            />
            <Button
              disabled={submitDisable}
              variant="primary"
              type="submit"
              className="f-button large_btn"
            >
              {t('送信')}
            </Button>
            <iframe
              title="dummyIframe"
              name="dummyIframe"
              style={{ display: 'none' }}
            />
          </Form>
          <span className="mg-r_xs f-s16_w500">
            {t(
              'お問い合わせフォームが正しく動作しない時や、アプリエラー時のログファイルを送付したいときは、COIAS開発チームに直接メールいただけますと幸いです。',
            )}
            <br />
            {t(
              '送信先メールアドレスはこちら: coias-dev[_at_]u-aizu.ac.jp ([_at_]を@に変換してください)',
            )}
          </span>
        </div>
        <Footer />
      </div>
      <SendCompletelyModal
        show={sendCompletelyModalShow}
        onHide={() => {
          setSendCompletelyModalShow(false);
        }}
      />
    </div>
  );
};

export default Contact;
