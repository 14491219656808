// OrbitInfoScatterPlotの描画範囲のコントロールを担うカスタムフック
import { useState, useEffect } from 'react';

const NO_MEANING_RANGE_OBJ = {
  xMin: 0.0,
  xMax: 1.0,
  yMin: 0.0,
  yMax: 1.0,
};

const DEFAULT_GRAPH_RECT_OBJ = {
  showRect: false,
  xPosMin: 0.0,
  xPosMax: 1.0,
  yPosMin: 0.0,
  yPosMax: 1.0,
};

// それぞれスクロール量に対するzoom量の速さを決める定数
const WHEEL_DELTA_TO_ZOOM_RATE = {
  semimajorAxis: 0.0005,
  diameter: 0.0005,
  eccentricity: 0.0005,
  inclination: 0.0005,
};

// それぞれ線形スケールかログスケールのどちらで表現するか
const LOG_OR_LINEAR = {
  semimajorAxis: 'log',
  diameter: 'log',
  eccentricity: 'linear',
  inclination: 'linear',
};

// 線形スケールとログスケールでの中心を得る関数
const GET_CENTER_FUNC = {
  log: (min, max) => Math.sqrt(min * max),
  linear: (min, max) => 0.5 * (min + max),
};

// 線形スケールとログスケールでの"幅"を得る関数
const GET_DIFF_FUNC = {
  log: (min, max) => max / min,
  linear: (min, max) => max - min,
};

// 線形スケールとログスケールで, 元の"幅"とzoomRateが与えられた時,
// zoom後の"幅"を得る関数
const GET_ZOOMED_DIFF_FUNC = {
  log: (diff, zoomRate) => {
    let newDiff;
    if (zoomRate > 0.0) {
      newDiff = diff ** (1.0 + zoomRate);
    } else {
      newDiff = diff ** (1.0 / (1.0 + Math.abs(zoomRate)));
    }
    return newDiff;
  },
  linear: (diff, zoomRate) => {
    let newDiff;
    if (zoomRate > 0.0) {
      newDiff = diff * (1.0 + zoomRate);
    } else {
      newDiff = diff * (1.0 / (1.0 + Math.abs(zoomRate)));
    }
    return newDiff;
  },
};

// 線形スケールとログスケールで, "幅"とグラフ上でのマウス移動割合が与えられた時,
// 範囲の"移動量"を得る関数
const GET_TRANSLATION_FUNC = {
  log: (diff, frac) => diff ** frac,
  linear: (diff, frac) => diff * frac,
};

// 線形スケールとログスケールで, 中心値と"幅"が与えられた時,
// 新たな最小値と最大値(範囲)を得る関数
const GET_ZOOMED_MIN_MAX_FUNC = {
  log: (center, diff) => [center * diff ** -0.5, center * diff ** 0.5],
  linear: (center, diff) => [center - 0.5 * diff, center + 0.5 * diff],
};

// 線形スケールとログスケールで, 範囲と"移動量"が与えられた時,
// 平行移動後の新たな最小値と最大値(範囲)を得る関数
const GET_DRAGGED_MIN_MAX_FUNC = {
  log: (min, max, translate) => [min * translate, max * translate],
  linear: (min, max, translate) => [min + translate, max + translate],
};

// 線形スケールとログスケールで, 原点の値と"幅"と相対位置が与えられた時,
// グラフ上のその相対位置での値を得る関数
const GET_GRAPH_VALUE_FUNC = {
  log: (min, diff, frac) => min * diff ** frac,
  linear: (min, diff, frac) => min + diff * frac,
};

/// 引数に全軌道情報のリストと描画モードを取り, ///////////
/// 全ての点がグラフ内に収まるような範囲を返す関数 /////////
const getDefaultRange = (orbitInfoListWithDiameter, plotMode) => {
  if (
    !Array.isArray(orbitInfoListWithDiameter) ||
    (plotMode !== 'diameter' &&
      plotMode !== 'eccentricity' &&
      plotMode !== 'inclination')
  ) {
    return NO_MEANING_RANGE_OBJ;
  }
  const INITIAL_LARGE_VALUE = 1.0e50;
  const INITIAL_SMALL_VALUE = -1.0e50;
  const ENLARGE_VALUE = 1.2;

  const xMin =
    (1.0 / ENLARGE_VALUE) *
    orbitInfoListWithDiameter.reduce(
      (minValue, currentItem) => Math.min(minValue, currentItem.semimajorAxis),
      INITIAL_LARGE_VALUE,
    );
  const xMax =
    ENLARGE_VALUE *
    orbitInfoListWithDiameter.reduce(
      (maxValue, currentItem) => Math.max(maxValue, currentItem.semimajorAxis),
      INITIAL_SMALL_VALUE,
    );
  const yMin =
    (1.0 / ENLARGE_VALUE) *
    orbitInfoListWithDiameter.reduce(
      (minValue, currentItem) => Math.min(minValue, currentItem[plotMode]),
      INITIAL_LARGE_VALUE,
    );
  const yMax =
    ENLARGE_VALUE *
    orbitInfoListWithDiameter.reduce(
      (maxValue, currentItem) => Math.max(maxValue, currentItem[plotMode]),
      INITIAL_SMALL_VALUE,
    );

  return { xMin, xMax, yMin, yMax };
};
////////////////////////////////////////////////////

// グラフの上でスクロールをした時, 中心を固定したまま範囲を変更する関数 //
const getWheeledRange = (prevRange, deltaY, plotMode) => {
  const xCenter = GET_CENTER_FUNC[LOG_OR_LINEAR.semimajorAxis](
    prevRange.xMin,
    prevRange.xMax,
  );
  const yCenter = GET_CENTER_FUNC[LOG_OR_LINEAR[plotMode]](
    prevRange.yMin,
    prevRange.yMax,
  );
  const xDiff = GET_DIFF_FUNC[LOG_OR_LINEAR.semimajorAxis](
    prevRange.xMin,
    prevRange.xMax,
  );
  const yDiff = GET_DIFF_FUNC[LOG_OR_LINEAR[plotMode]](
    prevRange.yMin,
    prevRange.yMax,
  );

  const xZoomRate = deltaY * WHEEL_DELTA_TO_ZOOM_RATE.semimajorAxis;
  const yZoomRate = deltaY * WHEEL_DELTA_TO_ZOOM_RATE[plotMode];

  const xNewDiff = GET_ZOOMED_DIFF_FUNC[LOG_OR_LINEAR.semimajorAxis](
    xDiff,
    xZoomRate,
  );
  const yNewDiff = GET_ZOOMED_DIFF_FUNC[LOG_OR_LINEAR[plotMode]](
    yDiff,
    yZoomRate,
  );

  const [xMin, xMax] = GET_ZOOMED_MIN_MAX_FUNC[LOG_OR_LINEAR.semimajorAxis](
    xCenter,
    xNewDiff,
  );
  let [yMin, yMax] = GET_ZOOMED_MIN_MAX_FUNC[LOG_OR_LINEAR[plotMode]](
    yCenter,
    yNewDiff,
  );

  return { xMin, xMax, yMin, yMax };
};
///////////////////////////////////////////////////////////////

// grabMode === "translate"の時, ///////////////////////////////
// グラフ上でドラッグ動作をしたら"幅"を固定して範囲を平行移動させる関数 ///
const getDraggedRange = (
  rangeWhenGrabed,
  plotMode,
  grabedPosition,
  currentPosition,
  graphSize,
) => {
  const xDiff = GET_DIFF_FUNC[LOG_OR_LINEAR.semimajorAxis](
    rangeWhenGrabed.xMin,
    rangeWhenGrabed.xMax,
  );
  const yDiff = GET_DIFF_FUNC[LOG_OR_LINEAR[plotMode]](
    rangeWhenGrabed.yMin,
    rangeWhenGrabed.yMax,
  );
  const xDraggedFraction =
    -(currentPosition.x - grabedPosition.x) / graphSize.x;
  const yDraggedFraction = (currentPosition.y - grabedPosition.y) / graphSize.y;

  const xTranslate = GET_TRANSLATION_FUNC[LOG_OR_LINEAR.semimajorAxis](
    xDiff,
    xDraggedFraction,
  );
  const yTranslate = GET_TRANSLATION_FUNC[LOG_OR_LINEAR[plotMode]](
    yDiff,
    yDraggedFraction,
  );

  const [xMin, xMax] = GET_DRAGGED_MIN_MAX_FUNC[LOG_OR_LINEAR.semimajorAxis](
    rangeWhenGrabed.xMin,
    rangeWhenGrabed.xMax,
    xTranslate,
  );
  const [yMin, yMax] = GET_DRAGGED_MIN_MAX_FUNC[LOG_OR_LINEAR[plotMode]](
    rangeWhenGrabed.yMin,
    rangeWhenGrabed.yMax,
    yTranslate,
  );

  return { xMin, xMax, yMin, yMax };
};
////////////////////////////////////////////////////////////////

// grabMode === "range"の時, ////////////////////////////////////
// グラフ上でドラッグ動作をしたら rectOnGraphオブジェクトを生成して返す関数
const getRectOnGraph = (prevRectOnGraph, grabedPosition, currentPosition) => {
  const showRect = prevRectOnGraph.showRect;
  const xPosMin =
    grabedPosition.x < currentPosition.x ? grabedPosition.x : currentPosition.x;
  const xPosMax =
    grabedPosition.x > currentPosition.x ? grabedPosition.x : currentPosition.x;
  const yPosMin =
    grabedPosition.y < currentPosition.y ? grabedPosition.y : currentPosition.y;
  const yPosMax =
    grabedPosition.y > currentPosition.y ? grabedPosition.y : currentPosition.y;

  return { showRect, xPosMin, xPosMax, yPosMin, yPosMax };
};
////////////////////////////////////////////////////////////////

// grabMode === "range"の時, ////////////////////////////////////
// ドラッグ動作で指定された範囲に対応するグラフの範囲を返す関数 //////////
const getDraggedRectRange = (
  rangeWhenGrabed,
  plotMode,
  grabedPosition,
  currentPosition,
  graphSize,
  graphClientPosition,
) => {
  // グラブした時の位置と離した時の位置を比較し小さい/大きい方を得る
  const xPosMin =
    grabedPosition.x < currentPosition.x ? grabedPosition.x : currentPosition.x;
  const xPosMax =
    grabedPosition.x > currentPosition.x ? grabedPosition.x : currentPosition.x;
  const yPosMin =
    grabedPosition.y < currentPosition.y ? grabedPosition.y : currentPosition.y;
  const yPosMax =
    grabedPosition.y > currentPosition.y ? grabedPosition.y : currentPosition.y;

  // 上記4つの座標のグラフ上での相対位置(左下原点)を得る
  // y方向については上下反転するため, minとmaxが入れ替わることに注意
  const xFracMin = (xPosMin - graphClientPosition.x) / graphSize.x;
  const xFracMax = (xPosMax - graphClientPosition.x) / graphSize.x;
  const yFracMin = 1.0 - (yPosMax - graphClientPosition.y) / graphSize.y;
  const yFracMax = 1.0 - (yPosMin - graphClientPosition.y) / graphSize.y;

  // x方向とy方向のグラフの"幅"を得る
  const xDiff = GET_DIFF_FUNC[LOG_OR_LINEAR.semimajorAxis](
    rangeWhenGrabed.xMin,
    rangeWhenGrabed.xMax,
  );
  const yDiff = GET_DIFF_FUNC[LOG_OR_LINEAR[plotMode]](
    rangeWhenGrabed.yMin,
    rangeWhenGrabed.yMax,
  );

  // 上記4つの座標のグラフ上での絶対値(左下原点)を得る
  const xMin = GET_GRAPH_VALUE_FUNC[LOG_OR_LINEAR.semimajorAxis](
    rangeWhenGrabed.xMin,
    xDiff,
    xFracMin,
  );
  const xMax = GET_GRAPH_VALUE_FUNC[LOG_OR_LINEAR.semimajorAxis](
    rangeWhenGrabed.xMin,
    xDiff,
    xFracMax,
  );
  const yMin = GET_GRAPH_VALUE_FUNC[LOG_OR_LINEAR[plotMode]](
    rangeWhenGrabed.yMin,
    yDiff,
    yFracMin,
  );
  const yMax = GET_GRAPH_VALUE_FUNC[LOG_OR_LINEAR[plotMode]](
    rangeWhenGrabed.yMin,
    yDiff,
    yFracMax,
  );

  return { xMin, xMax, yMin, yMax };
};
////////////////////////////////////////////////////////////////

/// ----- MAIN CUSTOM HOOK --------------------------------------
/// 引数: orbitInfoListWithDiameter: 軌道要素情報リスト
/// 　　  plotMode: "diameter" | "eccentricity" | "inclination"
/// 　　  grabMode: "translate" | "range"
/// 　　  graphRef: グラフに貼ったref
/// 　　  graphDivRef: グラフの親のdivに貼ったref
/// 返値: range: {xMin: number, xMax: number, yMin: number, yMax: number}
/// 　　  rectOnGraph: {showRect: bool, xPosMin: number, xPosMax: number, yPosMin: number, yPosMax: number}
/// 　　  restoreDefaultRange: () => {} デフォルトの範囲に戻す
/// 　　  onGrabGraph: (e) => {} グラフのonMouseDownハンドラにセットされる
/// 　　  onWheelGraph: (e) => {} グラフのonWheelハンドラにセットされる
const useRangeControl = (
  orbitInfoListWithDiameter,
  plotMode,
  grabMode,
  graphRef,
  graphDivRef,
) => {
  let isMouseMoveSleeped = false;
  let rangeWhenGrabed = { xMin: 0, xMax: 0, yMin: 0, yMax: 0 };
  const graphSize = { x: 0, y: 0 };
  const graphClientPosition = { x: 0, y: 0 };
  const grabedPosition = { x: 0, y: 0 };
  const currentPosition = { x: 0, y: 0 };

  // state変数 //////////////////////////////////////
  const [range, setRange] = useState(NO_MEANING_RANGE_OBJ);
  const [rectOnGraph, setRectOnGraph] = useState(DEFAULT_GRAPH_RECT_OBJ);
  ///////////////////////////////////////////////////

  // イベントハンドラ定義 //////////////////////////////
  const restoreDefaultRange = () =>
    setRange(getDefaultRange(orbitInfoListWithDiameter, plotMode));

  const onMouseMove = (e) => {
    if (!isMouseMoveSleeped) {
      currentPosition.x = e.clientX;
      currentPosition.y = e.clientY;

      if (grabMode === 'translate') {
        // grabMode === "translate"の時, グラフを平行移動させる
        setRange(
          getDraggedRange(
            rangeWhenGrabed,
            plotMode,
            grabedPosition,
            currentPosition,
            graphSize,
          ),
        );
      } else {
        // grabMode === "range"の時, ドラッグ動作で指定できる範囲を示す四角を描画する
        setRectOnGraph((prevRectOnGraph) =>
          getRectOnGraph(prevRectOnGraph, grabedPosition, currentPosition),
        );
      }

      isMouseMoveSleeped = true;
      setTimeout(() => (isMouseMoveSleeped = false), 10);
    }
  };

  const onMouseUp = (e) => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);

    // grabMode === "range"の時, グラフの範囲をドラッグ動作で指定した範囲に変更する
    if (grabMode === 'range') {
      setRange(
        getDraggedRectRange(
          rangeWhenGrabed,
          plotMode,
          grabedPosition,
          currentPosition,
          graphSize,
          graphClientPosition,
        ),
      );
    }

    // 範囲を指定する四角を消す
    setRectOnGraph((prevRectOnGraph) => ({
      ...prevRectOnGraph,
      showRect: false,
    }));
  };

  const onGrabGraph = (e) => {
    rangeWhenGrabed = { ...range };
    grabedPosition.x = e.clientX;
    grabedPosition.y = e.clientY;
    graphSize.x = graphRef.current?.chartArea.width;
    graphSize.y = graphRef.current?.chartArea.height;
    if (graphDivRef.current.getBoundingClientRect) {
      graphClientPosition.x =
        graphDivRef.current.getBoundingClientRect().left +
        graphRef.current?.chartArea.left;
      graphClientPosition.y =
        graphDivRef.current.getBoundingClientRect().top +
        graphRef.current?.chartArea.top;
    }

    // grabMode === "range"の時, ドラッグ動作で指定できる範囲を示す四角を描画する
    if (grabMode === 'range') {
      setRectOnGraph({
        ...DEFAULT_GRAPH_RECT_OBJ,
        showRect: true,
      });
    }

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  const onWheelGraph = (e) => {
    setRange((prevRange) => getWheeledRange(prevRange, e.deltaY, plotMode));
  };
  ///////////////////////////////////////////////////

  // 副作用 //////////////////////////////////////////
  // plotModeが変わった時, 描画範囲をデフォルトに戻す
  useEffect(() => {
    restoreDefaultRange();
  }, [plotMode]);
  ///////////////////////////////////////////////////

  return [range, rectOnGraph, restoreDefaultRange, onGrabGraph, onWheelGraph];
};
/// !----- MAIN CUSTOM HOOK -------------------------------------

export default useRangeControl;
