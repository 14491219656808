import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { TbHandGrab } from 'react-icons/tb';
import {
  Chart as ChartJS,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import useRangeControl from './useRangeControl';
import { provisionalCodeInfoList } from '../ProvisionalCodeInformationTable/provisionalCodeInfoList';

import './OrbitInfoScatterPlotStyle.scss';

ChartJS.register(
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
);

/// 引数に絶対等級を取り, 直径[km]を返す関数 ////////////
/// 参考: https://ja.wikipedia.org/wiki/%E7%B5%B6%E5%AF%BE%E7%AD%89%E7%B4%9A
const absoluteMagnitudeToDiameter = (absoluteMagnitude) => {
  const ALBEDO = 0.15; // アルベドは0.15を仮定
  const MSUN = -26.762; // 1auから見た太陽の視等級
  const ONE_AU_KM = 1.496e8; // km単位での1au

  const diameter =
    ((2.0 * ONE_AU_KM) / Math.sqrt(ALBEDO)) *
    10 ** ((MSUN - absoluteMagnitude) / 5);

  return diameter;
};
////////////////////////////////////////////////////

// 引数に描画モードを取り, 縦軸の軸ラベルを返す関数 //////
const getYAxisLabel = (plotMode) => {
  let yAxisLabel = '';
  if (plotMode === 'diameter') {
    yAxisLabel = '直径[km]';
  } else if (plotMode === 'eccentricity') {
    yAxisLabel = '軌道離心率';
  } else if (plotMode === 'inclination') {
    yAxisLabel = '軌道傾斜角[deg]';
  }

  return yAxisLabel;
};
//////////////////////////////////////////////////

/// ----- MAIN COMNPONENT ---------------------------------------
function OrbitInfoScatterPlot() {
  // データの準備 ////////////////////////////////////
  // nullが含まれるデータは省き, 直径[km]の概算値を追加 ///
  const orbitInfoListWithDiameter = provisionalCodeInfoList
    .filter(
      (item) =>
        item?.absoluteMagnitude &&
        item?.semimajorAxis &&
        item?.eccentricity &&
        item?.inclination,
    )
    .map((item) => {
      const itemCopy = JSON.parse(JSON.stringify(item));
      itemCopy.diameter = absoluteMagnitudeToDiameter(item.absoluteMagnitude);
      return itemCopy;
    });
  //////////////////////////////////////////////////

  const { t, i18n } = useTranslation();
  const graphDivRef = useRef(null);
  const graphRef = useRef(null);

  // state変数・カスタムフック /////////////////////////
  const [plotMode, setPlotMode] = useState('diameter'); // 縦軸のモード
  const [grabMode, setGrabMode] = useState('translate'); //グラブモード
  const [range, rectOnGraph, restoreDefaultRange, onGrabGraph, onWheelGraph] =
    useRangeControl(
      orbitInfoListWithDiameter,
      plotMode,
      grabMode,
      graphRef,
      graphDivRef,
    ); // 描画範囲

  const [data, setData] = useState({ datasets: [{ data: [] }] }); // Scatterコンポーネントに入力するデータ
  const [options, setOptions] = useState({}); // Scatterコンポーネントに入力するオプション
  //////////////////////////////////////////////////

  // 副作用 /////////////////////////////////////////
  // plotModeが変わった時, dataを再設定する
  useEffect(() => {
    setData({
      datasets: [
        {
          backgroundColor: 'rgba(255, 99, 132, 1)',
          data: orbitInfoListWithDiameter.map((item) => {
            return { x: item.semimajorAxis, y: item[plotMode] };
          }),
        },
      ],
    });
  }, [plotMode]);

  // plotModeかrangeが変わった時, optionsを変更する
  useEffect(() => {
    setOptions({
      scales: {
        x: {
          min: range.xMin,
          max: range.xMax,
          type: 'logarithmic',
          ticks: { autoSkip: true, maxTicksLimit: 10 },
          title: {
            display: true,
            text: t('軌道長半径[au]'),
            font: { size: 25 },
          },
        },
        y: {
          min: range.yMin,
          max: range.yMax,
          type: plotMode === 'diameter' ? 'logarithmic' : 'linear',
          ticks: { autoSkip: true, maxTicksLimit: 10 },
          title: {
            display: true,
            text: t(getYAxisLabel(plotMode)),
            font: { size: 25 },
          },
        },
      },
      animation: false,
      plugins: {
        legend: { display: false },
        datalabels: {
          display: false,
        },
        tooltip: {
          yAlign: 'bottom',
          callbacks: {
            label: (tooltipItem) => {
              const idx = tooltipItem.dataIndex;
              const thisData = orbitInfoListWithDiameter[idx];

              const returnStrArr = [];
              returnStrArr.push(`${thisData.provisionalCode}`);
              returnStrArr.push(
                `H=${thisData.absoluteMagnitude.toFixed(1)}mag`,
              );
              returnStrArr.push(`D=${thisData.diameter.toFixed(2)}km`);
              returnStrArr.push(`a=${thisData.semimajorAxis.toFixed(1)}au`);
              returnStrArr.push(`e=${thisData.eccentricity.toFixed(2)}`);
              returnStrArr.push(`i=${thisData.inclination.toFixed(1)}deg`);

              return returnStrArr.join(', ');
            },
          },
        },
      },
    });
  }, [plotMode, range, i18n.language]);

  // グラフ固有のwheelを実装するためにイベントリスナを貼る.
  // そのために固有のハンドラも用意する
  const onWheel = (e) => {
    e.preventDefault();
    onWheelGraph(e);
  };
  useEffect(() => {
    graphDivRef.current?.addEventListener('wheel', onWheel, { passive: false });
    return () => {
      graphDivRef.current?.removeEventListener('wheel', onWheel, {
        passive: false,
      });
    };
  });
  //////////////////////////////////////////////////

  return (
    <>
      <div>
        <Button
          variant={plotMode === 'diameter' ? 'primary' : 'secondary'}
          className="f-button"
          onClick={() => setPlotMode('diameter')}
        >
          {t('直径')}
        </Button>
        <Button
          variant={plotMode === 'eccentricity' ? 'primary' : 'secondary'}
          className="f-button"
          onClick={() => setPlotMode('eccentricity')}
        >
          {t('離心率')}
        </Button>
        <Button
          variant={plotMode === 'inclination' ? 'primary' : 'secondary'}
          className="f-button"
          onClick={() => setPlotMode('inclination')}
        >
          {t('傾斜角')}
        </Button>
      </div>
      <div
        className={
          grabMode === 'translate'
            ? 'grabable-graph'
            : 'grabable-graph non-grab'
        }
      >
        <div ref={graphDivRef}>
          <Scatter
            data={data}
            options={options}
            ref={graphRef}
            onMouseDown={(e) => onGrabGraph(e)}
            onWheel={(e) => onWheel(e)}
          />
        </div>
      </div>
      <div className="scatter-graph-bottom-button-spacer">
        <Button
          variant="secondary"
          className="f-button"
          onClick={() => restoreDefaultRange()}
        >
          {t('範囲リセット')}
        </Button>
        <Button
          variant={grabMode === 'translate' ? 'primary' : 'secondary'}
          className="f-button right-first icon-button"
          onClick={() => setGrabMode('translate')}
        >
          <TbHandGrab />
        </Button>
        <Button
          variant={grabMode === 'range' ? 'primary' : 'secondary'}
          className="f-button icon-button"
          onClick={() => setGrabMode('range')}
        >
          <MdOutlineCheckBoxOutlineBlank />
        </Button>
      </div>

      {/* grabMode === "range" の時, グラフ上でドラッグをすると範囲を示す四角を描画する*/}
      {rectOnGraph.showRect ? (
        <div
          style={{
            border: '1px dotted #000',
            position: 'absolute',
            left: `${rectOnGraph.xPosMin}px`,
            width: `${rectOnGraph.xPosMax - rectOnGraph.xPosMin}px`,
            top: `${rectOnGraph.yPosMin}px`,
            height: `${rectOnGraph.yPosMax - rectOnGraph.yPosMin}px`,
          }}
        ></div>
      ) : (
        <></>
      )}
    </>
  );
}
/// !----- MAIN COMNPONENT ---------------------------------------

export default OrbitInfoScatterPlot;
