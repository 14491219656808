import React from 'react';

import './ModalStyle.scss';

// plugin
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SendCompletelyModal = ({ show, onHide }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('送信完了')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="contents-text_only">
          <span>
            {t(
              'お問い合わせの送信が完了しました。いただいた内容は開発チームでお預かり致します。',
            )}
          </span>
          <p style={{ paddingTop: '10px', fontSize: '0.8em' }}>
            {t(
              'なお、COIASの運営はボランティアベースとなります。お返事にお時間を頂戴する場合がございますが、予めご了承ください。',
            )}
          </p>
        </Modal.Body>
        <Modal.Footer className="one_button">
          <Button variant="secondary" onClick={onHide}>
            {t('閉じる')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendCompletelyModal;
