import React from 'react';
import { useTranslation } from 'react-i18next';
import { provisionalCodeInfoList } from './provisionalCodeInfoList';

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

function ProvisionalCodeInformationTable() {
  const { t } = useTranslation();

  // テーブルに記載されるデータを再定義
  const provisionalCodeInfoListForTable = provisionalCodeInfoList.flatMap(
    (item, index) => {
      let note;
      if (item.oppositions === null) {
        note = item.note;
      } else {
        note =
          item.note.length === 0
            ? `${t('衝')}${item.oppositions}${t('回')}`
            : `${item.note}、${t('衝')}${item.oppositions}${t('回')}`;
      }

      return {
        ...item,
        userNames: item.detail.map((d) => d.userName).join(', '),
        id: index + 1,
        note: note,
      };
    },
  );

  // クリックでexpandされる中身を再定義
  const expandRow = {
    renderer: (row) => {
      const orbitInfoStrArr = [];

      // nullチェック
      let includeNull = false;
      Object.keys(row).forEach((key) => {
        if (row[key] === null) {
          includeNull = true;
        }
      });

      if (!includeNull) {
        orbitInfoStrArr.push(`H=${row.absoluteMagnitude.toFixed(1)}mag`);
        orbitInfoStrArr.push(`a=${row.semimajorAxis.toFixed(1)}au`);
        orbitInfoStrArr.push(`e=${row.eccentricity.toFixed(2)}`);
        orbitInfoStrArr.push(`i=${row.inclination.toFixed(1)}deg`);
        orbitInfoStrArr.push(`uncertainty=${row.uncertainty}`);
        orbitInfoStrArr.push(`衝${row.oppositions}回`);
      } else {
        orbitInfoStrArr.push('cannot get orbit information');
      }

      return (
        <div>
          {row.detail.map((d) => (
            <p>
              {d.userName} {t('さんの測定時点での天体名')}: {d.HNames}
            </p>
          ))}
          <p>{`(${orbitInfoStrArr.join(', ')})`}</p>
        </div>
      );
    },
    showExpandColumn: true,
  };

  function ProvisionalCodeFormatter(cell, _) {
    return (
      <a
        href={`https://www.minorplanetcenter.net/db_search/show_object?object_id=${cell.replace(
          ' ',
          '+',
        )}`}
        style={{ textDecoration: 'underline' }}
        target="_blank"
        rel="noreferrer noopener"
      >
        {cell}
      </a>
    );
  }

  return (
    <div className="data_analysis_provisional_table">
      <BootstrapTable
        keyField="id"
        data={provisionalCodeInfoListForTable}
        columns={[
          {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: (_) => ({ width: '50px' }),
          },
          {
            dataField: 'provisionalCode',
            text: t('仮符号'),
            filter: textFilter(),
            sort: true,
            formatter: ProvisionalCodeFormatter,
            headerStyle: (_) => ({ width: '95px' }),
          },
          {
            dataField: 'userNames',
            text: t('測定者（敬称略）'),
            filter: textFilter(),
            sort: true,
          },
          {
            dataField: 'note',
            text: t('備考'),
            filter: textFilter(),
            headerStyle: (_) => ({ width: '20%' }),
          },
        ]}
        filter={filterFactory()}
        pagination={paginationFactory({
          sizePerPageList: [
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
            { text: '200', value: 200 },
          ],
        })}
        expandRow={expandRow}
        striped
      />
    </div>
  );
}

export default ProvisionalCodeInformationTable;
