import React, { useState } from 'react';

import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

import './ModalStyle.scss';

// plugin
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SignUpModal = ({
  show,
  onHide,
  signUpName,
  setSignUpName,
  signUpPassword,
  setSignUpPassword,
  signUpRePassword,
  setSignUpRePassword,
  signUpEmail,
  setSignUpEmail,
  passwordErrorMessage,
  handleSignUp,
  emailErrorMessage,
}) => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
          setIsRevealPassword(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('新規登録')}</Modal.Title>
        </Modal.Header>
        <Form className="contents_wrap" onSubmit={(e) => handleSignUp(e)}>
          <Modal.Body className="mb-5">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                <span className="label_text">
                  {t('ニックネームを入力してください')}
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t('やまだ')}
                value={signUpName}
                onChange={(e) => setSignUpName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                <span className="label_text">
                  {t('メールアドレスを入力してください（IDに使用します）')}
                </span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="xxxxxxxxxx@gmail.com"
                value={signUpEmail}
                onChange={(e) => setSignUpEmail(e.target.value)}
                required
              />
              <p style={{ color: 'red' }}>{emailErrorMessage}</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                <span className="label_text">
                  {t('パスワードを入力してください')}
                </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  value={signUpPassword}
                  onChange={(e) => setSignUpPassword(e.target.value)}
                  type={isRevealPassword ? 'text' : 'password'}
                  required
                />

                <InputGroup.Text
                  style={{ backgroundColor: 'white' }}
                  onClick={() => setIsRevealPassword(!isRevealPassword)}
                >
                  {isRevealPassword ? (
                    <BsEyeFill size={20} />
                  ) : (
                    <BsEyeSlashFill size={20} />
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="" controlId="formBasicPassword">
              <Form.Label>
                <span className="label_text">
                  {t('もう一度パスワードを入力してください')}
                </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  value={signUpRePassword}
                  onChange={(e) => setSignUpRePassword(e.target.value)}
                  type={isRevealPassword ? 'text' : 'password'}
                  required
                />

                <InputGroup.Text
                  style={{ backgroundColor: 'white' }}
                  onClick={() => setIsRevealPassword(!isRevealPassword)}
                >
                  {isRevealPassword ? (
                    <BsEyeFill size={20} />
                  ) : (
                    <BsEyeSlashFill size={20} />
                  )}
                </InputGroup.Text>
              </InputGroup>
              <p style={{ color: 'red' }}>{passwordErrorMessage}</p>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="two_button">
            <Button variant="secondary" onClick={onHide}>
              {t('キャンセル')}
            </Button>
            <Button type="submit" variant="primary">
              {t('登録')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default SignUpModal;
