import React from 'react';

import './ModalStyle.scss';

// plugin
import { Button, Modal } from 'react-bootstrap';

const ImportantNoticeModal = ({ show, onHide }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>メンテナンス中 / Under Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body className="contents-text_only">
          <p style={{ fontWeight: 'bold' }}>
            COIASは現在メンテナンス中です。ログイン後の測定画面には遷移できません。
          </p>
          <p>
            MPCより異なる天体への重複したパーソナル符号の使用を指摘されました。
            問題の解決には相応の整理が必要なため、11/28の18:00より長期メンテナンスを実施します。
            ご不便をお掛け致しますが、プロジェクトの長期継続のためご理解いただきたく存じます。
          </p>
          <p style={{ textDecoration: 'underline' }}>
            <a href="https://docs.google.com/forms/d/12hb0dxGq01bILTJC2_f8M48jRLoQomdFflDou35Zw38/">
              ユーザ層調査のため、アンケートを実施中です（所要時間1分）。ぜひご回答ください。
            </a>
          </p>
          <br />
          <p style={{ fontWeight: 'bold' }}>
            COIAS is under maintenance. You cannot go to the measurement page
            after login.
          </p>
          <p>
            MPC pointed out the use of duplicate personal codes for different
            celestial bodies. To resolve the issue, we will conduct long-term
            maintenance from 18:00 JST, November 28th. Thank you for your
            cooperation in advance.
          </p>
          <p style={{ textDecoration: 'underline' }}>
            <a href="https://docs.google.com/forms/d/12hb0dxGq01bILTJC2_f8M48jRLoQomdFflDou35Zw38/">
              We are conducting a user survey for a demographic study. Please
              take a minute to complete the survey.
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer className="one_button">
          <Button variant="secondary" onClick={onHide}>
            閉じる / Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportantNoticeModal;
