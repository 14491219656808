import React from 'react';

import '../CharacterTalk/CharacterTalkStyle.scss';
import { useTranslation } from 'react-i18next';

const CharacterTalk = () => {
  const { t, i18n } = useTranslation();
  const miraTalkClass =
    i18n.language === 'en' ? 'mira_talk mira_talk_en' : 'mira_talk';
  const aoTalkClass = i18n.language === 'en' ? 'ao_talk ao_talk_en' : 'ao_talk';
  return (
    <div className="character_talk-wrap">
      <ul className="character_talk">
        <li className={miraTalkClass}>
          <span></span>
          {t('新しい小惑星を見つけたいけど、私達高校生でもできるのかな。')}
        </li>
        <li className={aoTalkClass}>
          <span></span>
          {t(
            '誰でも未発見の小惑星を探せるアプリができたんだって！ 使ってみない？',
          )}
        </li>
        <li className={miraTalkClass}>
          <span></span>
          {t('えっ、望遠鏡を持ってなくても小惑星を発見できるの？')}
        </li>
        <li className={aoTalkClass}>
          <span></span>
          {t(
            'すばる望遠鏡で撮った画像から、新しい小惑星を見つけて報告できるみたいだよ。',
          )}
        </li>
        <li className={miraTalkClass}>
          <span></span>
          {t('すごい、さっそくやってみようよ！')}
        </li>
        {/* <li className="mira_talk"><span></span></li> */}
        {/* <li className="ao_talk"><span></span></li> */}
      </ul>
    </div>
  );
};

export default CharacterTalk;
