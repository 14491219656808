import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
);

const MagHistogram = ({ magHistogramData }) => {
  const { t, i18n } = useTranslation();
  const [annotationLinePositions, setAnnotationLinePositions] = useState({
    hundred_pos: 0,
    kilo_pos: 0,
    tenkilo_pos: 0,
  });
  const [labelYPos, setLabelYPos] = useState(0);
  const [NXTicks, setNXTicks] = useState(0);

  // magHistogramデータから100m, 1km, 10kmの天体がグラフのどこに対応するのか計算
  // labelのy方向の位置も計算 /////////////////////////////////////////////
  useEffect(() => {
    if (magHistogramData && magHistogramData?.length !== 0) {
      const HUNDREDMAG = 25.5;
      const KILOMAG = 20.5;
      const TENKILOMAG = 15.5;

      const minIndex = 0;
      const maxIndex = magHistogramData.length - 1;
      const minIndexMag = magHistogramData[minIndex].mag_center;
      const maxIndexMag = magHistogramData[maxIndex].mag_center;

      const hundredIndex =
        minIndex +
        ((maxIndex - minIndex) / (maxIndexMag - minIndexMag)) *
          (HUNDREDMAG - minIndexMag);
      const kiloIndex =
        minIndex +
        ((maxIndex - minIndex) / (maxIndexMag - minIndexMag)) *
          (KILOMAG - minIndexMag);
      const tenkiloIndex =
        minIndex +
        ((maxIndex - minIndex) / (maxIndexMag - minIndexMag)) *
          (TENKILOMAG - minIndexMag);

      setAnnotationLinePositions({
        hundred_pos: hundredIndex,
        kilo_pos: kiloIndex,
        tenkilo_pos: tenkiloIndex,
      });

      let maxNumber = 0;
      magHistogramData.forEach((data) => {
        const number = data.n_numbered + data.n_karifugo + data.n_unknown;
        if (number > maxNumber) maxNumber = number;
      });
      setLabelYPos(0.5 * maxNumber);

      // 等級が整数の数だけラベルを配置したい
      // 最小と最大のbinの等級が整数の時、必要なラベルの数はfloor(max - min + 1.x)
      setNXTicks(Math.floor(Math.abs(maxIndexMag - minIndexMag + 1.1)));
    }
  }, [magHistogramData]);
  //////////////////////////////////////////////////////////////////////

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          font: { size: 16 },
          fontColor: '#282828',
          maxTicksLimit: NXTicks,
        },
        grid: { display: false },
        title: { display: true, text: t('見かけの等級'), font: { size: 25 } },
        stacked: true,
      },
      y: {
        ticks: {
          font: { size: 16 },
          fontColor: '#282828',
          callback: (value, index, values) => {
            //英語版では縦軸の数値に3桁ごとにカンマを打つだけにする
            if (i18n.language === 'en') {
              return Math.floor(value).toLocaleString();
            }

            let kanjiKeta;
            let reprValue;
            const mantissaStr = value.toExponential(1).split('e+')[0];
            const exponentInt = parseInt(value.toExponential(1).split('e+')[1]);
            // 縦軸の個数表記について, 万・億を使うが精度が2桁は保証されるようにする
            // 1万未満: 整数でそのまま表示
            if (value < 1.0e4) {
              kanjiKeta = '';
              reprValue = Math.floor(value);
            }
            // 1万以上10万未満: 有効数字2桁+万で表示, i.e., 3.5万
            else if (value >= 1.0e4 && value < 1.0e5) {
              kanjiKeta = '万';
              reprValue = mantissaStr;
            }
            // 10万以上1億未満: 有効数字2桁の整数+万で表示, i.e., 35万, 350万
            else if (value >= 1.0e5 && value < 1.0e8) {
              kanjiKeta = '万';
              reprValue = Math.floor(
                parseFloat(mantissaStr) * 10 ** (exponentInt - 4),
              );
            }
            // 1億以上10億未満: 有効数字2桁+億で表示, i.e., 3.5億
            else if (value >= 1.0e8 && value < 1.0e9) {
              kanjiKeta = '億';
              reprValue = mantissaStr;
            }
            // 10億以上: 有効数字2桁の整数+億で表示, i.e., 35億, 350億
            else if (value >= 1.0e9) {
              kanjiKeta = '億';
              reprValue = Math.floor(
                parseFloat(mantissaStr) * 10 ** (exponentInt - 8),
              );
            }
            return `${reprValue}${kanjiKeta}`;
          },
        },
        title: { display: true, text: t('天体数'), font: { size: 25 } },
        stacked: true,
      },
    },
    plugins: {
      annotation: {
        annotations: {
          hundred_line: {
            type: 'line',
            xMin: annotationLinePositions.hundred_pos,
            xMax: annotationLinePositions.hundred_pos,
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
          },
          hundred_label: {
            type: 'label',
            xValue: annotationLinePositions.hundred_pos - 0.8,
            yValue: labelYPos,
            content: t('直径 100 m'),
            font: { size: 18 },
            rotation: 270,
          },

          kilo_line: {
            type: 'line',
            xMin: annotationLinePositions.kilo_pos,
            xMax: annotationLinePositions.kilo_pos,
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
          },
          kilo_label: {
            type: 'label',
            xValue: annotationLinePositions.kilo_pos - 0.8,
            yValue: labelYPos,
            content: t('直径 1 km'),
            font: { size: 18 },
            rotation: 270,
          },

          tenkilo_line: {
            type: 'line',
            xMin: annotationLinePositions.tenkilo_pos,
            xMax: annotationLinePositions.tenkilo_pos,
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
          },
          tenkilo_label: {
            type: 'label',
            xValue: annotationLinePositions.tenkilo_pos - 0.8,
            yValue: labelYPos,
            content: t('直径 10 km'),
            font: { size: 18 },
            rotation: 270,
          },
        },
      },
      datalabels: { display: false },
    },
  };

  const labels = magHistogramData.map((data) => {
    // 入力される等級は小数点以下2桁まで. 小数点以下が0(=整数)の時だけラベルを出す
    const decimalStr = Math.floor(data.mag_center * 100).toString();
    let returnStr;
    if (decimalStr.slice(-2) === '00') {
      returnStr = Math.floor(data.mag_center).toString();
    } else {
      returnStr = '';
    }

    return returnStr;
  });
  const data = {
    labels, // x軸のラベルの配列
    datasets: [
      {
        label: t('確定番号天体'), // 凡例
        data: magHistogramData.map((data) => data.n_numbered), // データの配列(labelsと要素数同じ)
        backgroundColor: 'rgba(255, 0, 0, 0.8)', // グラフの棒の色
        categoryPercentage: 0.95,
        barPercentage: 0.95,
      },
      {
        label: t('仮符号天体'), // 凡例
        data: magHistogramData.map((data) => data.n_karifugo), // データの配列(labelsと要素数同じ)
        backgroundColor: 'rgba(0, 255, 0, 0.8)', // グラフの棒の色
        categoryPercentage: 0.95,
        barPercentage: 0.95,
      },
      {
        label: t('新天体候補'), // 凡例
        data: magHistogramData.map((data) => data.n_unknown), // データの配列(labelsと要素数同じ)
        backgroundColor: 'rgba(56, 85, 161, 0.8)', // グラフの棒の色
        categoryPercentage: 0.95,
        barPercentage: 0.95,
      },
    ],
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};
export default MagHistogram;
